section.corpoCope {
    margin: 15px;
    display: flex;
}

.f_corpoCope {
    justify-content: space-around;
    align-items: center;
}

.perfil_cooperador {
    height: 300px;
    display: flex;
    justify-content: space-between;
    transform: translateY(-180px);

}

.img_perfil_cooperador {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
}

.leg_perfil {
    background-color: #686d25;
    padding: 20px;
    padding-left: 40px;
    width: 300px;
    transform: translateX(-20px);
    border-radius: 0 10px 10px 0;
    z-index: -1;

    display: grid;
    justify-content: flex-start;
    align-items:flex-start;
    margin:  0 auto;
}

.leg_perfil p {
    margin: 10px 0;
    width: 100%;
}




/*  */
html {
    scroll-behavior: smooth;
}

.h1_sobre_fazenda {
    color: #fff;
    text-align: center;
    margin: 20px 0;
    font-size: 30px;
}

.conteudo_fazenda {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ver_mapa_fazenda {
    padding: 5px 15px;
    border: 2px solid #fff;
    font-weight: bold;
    color: #fff;
    font-size: 17px;
    border-radius: 5px;
    background-color: #686d25;
    position: relative;
    bottom: 10px;
    transition: .3s;
    cursor: pointer
}

.ver_mapa_fazenda:hover {
    font-size: 18px;
    border-radius: 5px;
    background-color: #3f4217;
    color: #fff;
}

/* Media Query */

@media (max-width: 750px) {
    .f_corpoCope {
        flex-direction: column;
    }
    .img_perfil_cooperador {
        height: 600px;
        border-radius: 10px 10px 0 0;
    }
    .perfil_cooperador {
        height: 600px;
        flex-direction: column;
    }
    .leg_perfil {
       margin-left: 20px;
        border-radius: 0 0 10px 10px;
    }
}

@media (max-width: 500px) {
    .conteudo_fazenda {
        height: 200px;
    }
}

@media (max-width: 375px) {
    .perfil_cooperador {
        height: 600px;
        flex-direction: column;
    }
}


/*Change of cooperator profile card */


/* @media (max-width: 375px) {
  
}
 */

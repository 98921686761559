/*Login*/

#section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url('../../public/img/GD-1_Prancheta\ 1.png');
    /* background-image: url('../components/imgs/CWAM/IMG_9703.jpg'); */
    /* background: url(../../); */
    background-repeat: no-repeat;
    background-size: cover;
}

.login_texto {
    color: #fff;
    padding: 20px;
    width: 100%;
    height: 100%;
    text-align: justify;
    margin: auto;
}

.login_texto h1 {
    color: #fff;
    text-align: center;
    font-size: 40px;
    margin: 30px 10px 50px 10px;
}

.p_s {
    justify-content: center;
}

.login,
.cadastro {
    margin-bottom: 100px;
    background-color: #999f55d3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.37);
    border-radius: 15px;
    width: 500px;
    padding-bottom: 50px;
}

.login input::placeholder {
    color: #fff;
}

.esconder{
   overflow: hidden;
   transition: 1s ;
}
.fundo_termos{
    margin: 70px;
    background: #424616;
    padding: 40px;
    border-radius: 30px;
}

.margem{
    margin: 20px;
    padding: 0;
    padding:10px;
    margin: 20px;
    width: 100%;
    text-align: left;
}

.margem h1{
    font-size: 25px;
    color: white;
}

 
/*Responsividade dos termos e compromissos*/

@media (max-width: 800px) {
    .fundo_termos{
        margin: 10px;
    }
}

@media (max-width: 400px) {
    .margem{
        margin-bottom: 10px;
        padding: 0;
        margin: 0px;
        width: 100%;
    }

    .margem h1{
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .margem p{
        margin-bottom: 10px;
        margin: 4px;
        padding: 0;
        margin: 0px;
        width: 100%;
    }
}
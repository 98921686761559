.video {
    margin: 15 0 15 0;
}

section #inicio-leilao {
    /* background: url("/img/Cultura Coop_Cuando Cubango II.png"); */
    background-position: center;
    background-size: cover;
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
}

.is-link:hover {
    background-color: #b46739;
}

section #inicio-leilao {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section.leilao_online {
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.fundo_leilao_online {
    /* background: url("../imgs/Cultura Coop_Cuando Cubango II.png"); */
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: 600px;
    max-width: 100%;
    margin-top: 5rem;
}

section.fundo_leilao_online::before {
    content: '';
    position: absolute;
    top: 1;
    left: 0;
    height: 600px;
    width: 100%;
    background: #00000094;
}

section.fundo_leilao_online {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section .welcome-leilao {
    position: absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section .welcome-leilao h1 {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    text-align: center;
    font-size: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

.l_divider {
    height: 100px;
}

section .welcome-leilao p {
    text-align: justify;
    color: #fff;
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 700px;
    z-index: 10;
}

section.leilao {
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.leilao .columns img {
    border-radius: 10px;
    max-width: 285px;
    background-size: cover;
    background-position: center;
    height: 270px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

section.leilao .mi {
    background: #424616;
    padding: 10px;
    margin: 40px;
    border-radius: 15px;
    height: 600px;
}

section.leilao p {
    font-weight: 600;
    text-align: center;
}

.welcome-leilao a.button {
    box-shadow: inset 250px 0 0 #9ba236;
    color: white;
    border: none;
    border-radius: 24px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.welcome-leilao a.button:hover {
    box-shadow: inset 250px 0 0 #b46739;
    color: white;
    transition: 0.5s ease-in;
}

a.button {
    border: none;
    border-radius: 24px;
    transition: .3s;
}

a.button:hover {
    background-color: #b46739;
}

video {
    width: 20%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lance_p {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 5px;
    border-bottom: 1px solid #b46739;
    text-align: center;
}

.lance_h2 {
    border-bottom: 1px solid #b46739;
}





/*formatacao do video do leilao*/

section#video{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
} 

.player-wrapper{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-fundo-leilao{
    padding: 10px;
    width: 30%;
    height: 360px;
    background: #cbd84468;
    border-radius: 10px;
}

.img-fundo-leilao img.img_logo{
 width: 320px;
 height: 150px;
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 0 auto;
}

.img-fundo-leilao
h1{
    color: #fff;
    text-align: center;
    font-size: 28px;
    margin: 20px 0;
}


/*Responsivideda do leilao Exclusivo*/

@media (max-width:896px) {
    section#video{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
    }

    .player-wrapper{
        width: 80%;
    }

    .img-fundo-leilao{
        padding: 10px;
        width: 57%;
        height: 250px;
        background: #cbd84468;
        border-radius: 10px;
    }
        
}


@media (max-width:906px) {
    .player-wrapper{
        width: 60%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
        
}



@media (max-width: 530px) {
    .img-fundo-leilao
    h1{
        color: #fff;
        text-align: center;
        font-size: 18px;
        margin: 0 auto;
    }
        
}


@media (max-width:891px) {

    section#video{
        margin-top: 10px;
    }

    .player-wrapper{
        margin-top: 80px;
        width: 90%;
        height: 20%;
        padding: 10px;

        font-size: 10px;
        /* margin-right: 25px; */
    }

    .img-fundo-leilao{
        width: 90%;
        height: 20%;
        /* margin-right: 25px; */
    }
        
}


@media (max-width:487px) {

    section#video{
        margin-top: 10px;
    }

    .player-wrapper{
        margin-top: 80px;
        width: 90%;
        height: 20%;
        padding: 10px;

        font-size: 10px;
        /* margin-right: 25px; */
    }

    .img-fundo-leilao{
        width: 90%;
        height: 20%;
        /* margin-right: 25px; */
    }
        
}
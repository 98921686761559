
.pagB{
    margin: 5px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: inline;
    background-color: #b46739;
    border: none;
    color: white;
    font-size: large;
}

.sect{
    width: 100%;
    background-color: #3f4217;
    text-align: center;
}
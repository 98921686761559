*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Times New Roman", sans-serif;
}

.tresimg {
    width: 120px;
    height: 110px;
}

.tresletra {
    width: 240px;
    margin: 10px;
    padding: 5px;
    font-size: 15px;
} 

@media(max-width:920px) {
    .tresimg {
        width: 90px;
        height: 80px;
    }
    .tresletra {
        width: 210px;
        margin: 5px;
        padding: 5px;
        font-size: 13px;
    }
    .cima_tres {
        transform: translateY(-30px);
        grid-gap: 10px;
    }
    .ca {
        padding: 0;
    }
    .vindo {
        height: 700px;
    }
    .ima {
        height: 700px;
    }
    .vindo_sombra {
        height: 700px;
    }
}

@media(max-width: 730px) {
    .tresletra {
        max-width: 170px;
        font-size: 12px;
    }
    .cima_tres {
        transform: translateY(-20px);
    }
}

@media(max-width: 620px) {
    .tresimg {
        width: 80px;
        height: 70px;
    }
    .tresletra {
        max-width: 150px;
        font-size: 11px;
    }
    .cima_tres {
        transform: translateY(-20px);
    }
}

@media(max-width: 575px) {
    .tres {
        width: 100%;
        overflow-x: scroll;
    }
    .cima_tres {
        width: 520px;
    }
}


/*Change to responsive cooperators page*/

@media(max-width: 590px) {
    #meio-home {
        padding: 5px;   
        margin-top: -200px;  
    }
}

@media(max-width: 490px) {
    #meio-home {
        padding: 5px;   
        margin-top: -490px;   
    }
    #meio-home .quadrado-1 { 
        width: 90px;
        display: flex;
    }

    #meio-home .quadrado { 
        width: 90px;
        display: flex;
        margin: 5px;
    }
}

@media(max-width: 300px) {
    #meio-home {
        padding: 5px;   
        margin-top: -340px;
    }
}

@media(max-width: 300px) {
    #meio-home .quadrado-1 { 
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
} 

/*Changes to responsive Cooperators*/
.txt-inicio-coop h1{
    font-size: 3rem;
} 

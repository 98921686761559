:root {
    --cor-hover: #585e0e;
    --cor-primaria: rgb(231, 241, 236);
}

.dp-menu {
    width: 140px;
    margin-top: 40px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin-right: 125px;
    margin-top: -10px;
}

.dp-menu ul {
    list-style-type: none;
    padding: 0;
}

.dp-menu ul li {
    display: inline;
    position: relative;
}

.dp-menu ul li a {
    color: var(--cor-primaria);
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    transition: .3s ease-in;
}

ul.txt-meu-Perfil {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp-menu ul ul li a:hover {
    background: linear-gradient(#9ba536, #585e0e);
    width: 190px;
    margin-left: -50px;
    text-align: center;
    border-radius: 10px;
}

/*sub menu*/
.dp-menu ul ul {
    display: none;
    left: 0;
    position: absolute;
    width: 100px;
    margin-right: 50px;
}

.dp-menu ul li:hover ul {
    display: block;
}

/*
.dp-menu ul ul {
    width: 150px
}

.dp-menu ul ul li a {
    display: block;
} */

/* PERFIF DO USUARIO */
.nav-link {
    /* width: 290px; */
    /* padding: 10px; */
    border-bottom: 1px solid whitesmoke;
    width: 75px;
    /* display: grid;
    justify-content: center;
    align-items: center; */
    /* border: 3px solid #7b8122;
    border-radius: 20px; */
    /* outline: none;
    font-size: 0.9rem;
    z-index: 100; */
}

/* .nav-link {
    width: 290px;
    padding: 10px;
    margin: 50px;
    display: grid;
    justify-content: center;
    align-items: center;

    outline: none;
    font-size: 0.9rem;
    z-index: 100;
} */

/* MEU PERFil DO USUARIO */
.meuPerfil {
    /* width: 100px; */
    display: grid;
    align-items: center;
    justify-content: center;
}

.backColor {
    transform: translateY(-90px);
    margin-bottom: 100px;
    /* background-color: #585e0e; */
    background: linear-gradient(#9ba536, #585e0e);
    border-radius: 10px;
    padding: 10px;
    width: 450px;
    height: 70%;
}

.backColor p {
    margin: 20px 0;
}

.backColor p strong {
    color: #fff;
    font-size: 18px;
}

/* NOVO */


.card_user {
    padding: 20px;
    padding-top: 0;
}

.card_user2 {
    display: grid;
    grid-template-columns: .2fr 1fr;
    margin: auto;
    width: 80%;
}

.card_user2>div {
    margin: 10px;
    margin-top: 0;
}

.card_filho {
    min-width: 300px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.517);
    padding-top: 20px;
}

.img_do_user {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-image: url('../../components/imgs/cooperador.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
}

.dados_do_user {
    color: #000;
    background-color: #e0dfdf;
    padding: 20px;
    margin-top: 10px;
    border-radius: 0 0 10px 10px;
}

.dados_do_user h1 {
    color: #000;
    font-weight: bold;
    font-size: 20px;
}

.dados_do_user h3 {
    color: #444;
    font-size: 14px;
    font-weight: bold;
}

.dados_do_user p {
    color: #000;
    margin: 10px 0;
}

.card_filho2 {
    display: flex;
    flex-direction: column;
}

.card_filho2 .O_menu_do_usuario {
    background-color: #eee;
    border-radius: 0 0 10px 10px;
}


.menu_do_user {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_filho2 .O_menu_do_usuario ul {
    display: flex;
}

.card_filho2 .O_menu_do_usuario li {
    padding: 10px;
    margin: 0 20px;
    font-weight: 600;
    cursor: pointer;
}

.h1_text_user {
    color: #fff;
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
}

.sair_user {
    padding: 5px 30px;
    font-size: 15px;
    font-weight: bold;
    border: 2px solid #585e0e;
    background-color: #9ba536;
    border-radius: 20px;
    color: #fff;
    margin: 10px;
    margin-right: 30px;
    cursor: pointer;
}

.card_filho2 .corpo_das_informacoes {
    height: 350px;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.277);

}

.corpo_das_informacoes table {
    width: 100%;
}

.corpo_das_informacoes table tr {
    border: none;
}

.corpo_das_informacoes table tr:nth-child(1) {
    background-color: #b46739;
    color: #fff;
}

.corpo_das_informacoes table tr:nth-child(1) th:nth-child(1) {
    border-top-left-radius: 10px;
}

.corpo_das_informacoes table tr:nth-child(1) th:last-child {
    border-top-right-radius: 10px;
}

.corpo_das_informacoes table th {
    padding: 10px;
    color: #fff;
}

.corpo_das_informacoes table td {
    padding: 10px;
}

.corpo_das_informacoes table tr:nth-child(even) {
    background-color: #efeeee;
}

.corpo_das_informacoes table {
    background-color: #fff;
}

.corpo_das_informacoes form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: "hidden";
}

.btn_ver_comp_user, .btn_ver_comp_user_comprovativo {
    padding: 5px 10px;
    font-weight: bold;
    border: 2px solid #854a28;
    background-color: #b46739;
    border-radius: 20px;
    color: #fff;
    margin: 10px;
    margin-right: 30px;
    cursor: pointer;
    width: 100px;
}

.btn_ver_comp_user_comprovativo {
    width: 200px;
    font-size: 18px;
    background-color: #fff;
    color: #854a28;
    transition: .3s;
}

.btn_ver_comp_user_comprovativo:hover {
    background-color: #854a28;
    color: #fff;
    border-color: #fff;
}

.ver_img_table_compras {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-image: url('/public/carrinho.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
}

.menu_color_link_a {
    color: #000;
}

.Laranja_dados_user {
    background-color: #b46739;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

.Laranja_dados_user h2 {
    font-size: 18px;
}

.flex_form_user {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    margin: auto;
    grid-gap: 20px;
    margin-top: 20px;
}

.flex_form_user label {
    color: #b46739;
    font-weight: bold;
}

.flex_form_user input, #select_genero_perfil {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid gray;
    outline: none;
    background-color: #ddd;
}

#select_genero_perfil {
    color: #000;
    min-width: 170px;
    padding: 6px;
}

.flex_form_btn_user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
    /* margin-left: -90px; */
}

.editar_btn_user {
    padding: 10px 20px;
    font-weight: bold;
    border: 2px solid #854a28;
    background-color: #b46739;
    border-radius: 5px;
    color: #fff;
    margin: 10px;
    /* margin-left: 100px; */
    /* margin-right: 100px; */
    cursor: pointer;
    margin-top: 10px;
}

.dois_user_form_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dois_user_form_btn>div:nth-child(2) {
    margin-top: 10px;
}

.img_do_user_user {
    width: 300px;
}

#laranja_cima_text_info_compra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b46639;
    color: #fff;
    padding: 5px;
}

#laranja_cima_text_info_compra h2 {
    font-size: 20px;
    margin-left: 10px;
}

.informacao_da_compra_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

/* Fromatação da seta de voltar */
.btn_voltar_info_compra {
    background-color: #b46639;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 20px;
    transition: .3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.btn_voltar_info_compra:hover {
    background-color: #fff;
    transform: scale(1.2);
}

.btn_voltar_info_compra:hover .seta_branca_voltar {
    background-color: #854a28;
}

.seta_branca_voltar {
    background-color: #fff;
    width: 28px;
    height: 5px;
    margin: 5px;
    border-radius: 10px;
    position: absolute;
}

.seta_branca_voltar:nth-child(1) {
    transform: translate(-6.5px, -4px) rotate(-40deg);
    width: 15px;
}

.seta_branca_voltar:nth-child(3) {
    transform: translate(-6.5px, 4px) rotate(40deg);
    width: 15px;
}

.verificar_email {
    background-color: rgba(255, 166, 0, 0.439);
    padding: 10px;
    margin: 10px;
    text-align: center;
    font-weight: bold;
}

.pendenteCart {
    color: orange;
    font-weight: bold;
}

.negadoCart {
    color: red;
    font-weight: bold;
}

.aceiteCart {
    color: green;
    font-weight: bold;
}

.PaideTodosModalcartModal {
    display: flex;
    justify-content: space-around;
    align-items: center;
}




























.estilodaListaDaModal {
    width: 100%;
}

.estilodaListaDaModal ul {
    width: 100%;
    list-style: none;
}

.estilodaListaDaModal li {
    list-style: none;
    width: 100%;
    margin: 0;
}

.estilodaListaDaModalPai li {
    margin: 0;
}

.estilodaListaDaModalFilho {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.estilodaListaDaModalFilho li:first-child {
    background-color: #555;
    color: #fff;
    padding: 15px;
    margin: 0;
}

.estilodaListaDaModalFilho li:nth-child(2) {
    padding: 15px;
    margin: 0;
}

.corescuramodalcart {
    background-color: #eee;
}

.imgDoCartUserModal {
    background-image: url('/public/gado.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 600px;
    height: 250px;
    margin: auto;
    border-radius: 10px;
}
 
.info_doLote {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'imgDOGeral  Legaenda_doLoteGeralInfo';
    justify-content: center;
    align-items: center; 
    margin: 0 auto;
    width: 90%;
}

.imgDOGeral {
    grid-area: imgDOGeral;
    width: 400px;
    height: 250px;
    /* background-image: url('/public/gado.jpeg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin:  0 auto;
}

.Legaenda_doLoteGeralInfo {
    grid-area: Legaenda_doLoteGeralInfo;
    background-color: #999f55d3;
    padding: 10px;
    width: 600px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 50px; 
    justify-content: center;
    align-items: center;
    margin:  0 auto;
}


/**/
@media(max-width:900px){
    .info_doLote {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 'imgDOGeral' 'Legaenda_doLoteGeralInfo';
    }
    .imgDOGeral { 
        width: 100%;
        height: 250px;  
    }
    .Legaenda_doLoteGeralInfo { 
        background-color: #999f55d3;
        padding: 10px;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        margin-top: 20px;
    }
}
/**/




.Legaenda_doLoteGeralInfo h1{
    text-align: center;
    color: #fff;
    font-size: 25px;
}
.divVerLoteGeralCompra{
    background-color: transparent;
}
.divVerLoteGeralCompra tr:nth-child(even){
    background-color: transparent !important;
}
.divVerLoteGeralCompra td{
    color: #fff;
}
@media(max-width:700px) {
    .PaideTodosModalcartModal {
        flex-direction: column;
    }

    .imgDoCartUserModal {
        width: 300px;
        height: 200px;
    }
}

@media(max-width:510px) {

    .imgDoCartUserModal {
        width: 250px;
        height: 190px;
    }

    .estilodaListaDaModal {
        width: 70%;
        font-size: 0.7rem;
    }
}

@media(max-width:432px) {

    .imgDoCartUserModal {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .estilodaListaDaModalFilho li:first-child {
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 90px;
        margin-right: 10px;
    }

    .estilodaListaDaModalFilho li:nth-child(2) {
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 90px;
        margin-right: 10px;
    }
}

@media(max-width:360px) {

    .imgDoCartUserModal {
        width: 180px;
        height: 180px;
    }
}

@media(max-width:328px) {

    .imgDoCartUserModal {
        width: 150px;
        height: 130px;
        margin-left: -20px;
    }


    .estilodaListaDaModalFilho li:first-child {
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 70px;
        /* margin-right: 10px; */
    }

    .estilodaListaDaModalFilho li:nth-child(2) {
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 70px;
        /* margin-right: 10px; */
    }
}




/*Responsividade do Perfil do Usuario*/

@media screen and (max-width: 1350px) {
    .card_user2 {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .corpo_das_informacoes>div {
        overflow-x: scroll;
    }
}

@media screen and (max-width: 1070px) {
    .card_user2 {
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse;
    }

    .card_filho {
        margin: auto;
        background-color: transparent;
        box-shadow: none;
    }

    .card_filho>div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: auto;
    }

    .card_filho img {
        border-radius: 10px;
    }

    .dados_do_user {
        border-radius: 0 10px 10px 0;
        padding: 20px;
    }
}

@media screen and (max-width: 680px) {
    .flex_form_user {
        grid-template-columns: 1fr 1fr;
        margin-left: 10px;
    }

    .flex_form_btn_user {
        margin-top: 30px;
        /* margin-left: -90px; */
    }

    .editar_btn_user {
        margin-left: 0;
    }
}

@media screen and (max-width: 551px) {
    section.fundo_entrar h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        margin-top: -2px;
    }

    .h1_text_user {
        font-size: 24px;
    }
}

@media screen and (max-width: 530px) {
    #laranja_cima_text_info_compra {
        flex-direction: column;
    }

    .btn_ver_comp_user_comprovativo {
        margin: 0;
    }
}

@media screen and (max-width: 800px) {
    section.meuPerfil {
        margin-top: 50px;
    }

    .card_filho>div {
        flex-direction: column;
    }

    .card_filho img {
        z-index: 2;
    }

    .dados_do_user {
        width: 300px;
        border-radius: 0 0 10px 10px;
        padding-top: 40px;
        transform: translateY(-20px);
    }
}

@media screen and (max-width: 560px) {
    section.meuPerfil {
        margin-top: 80px;
    }

    .backColor {
        transform: translateY(-90px);
        margin-bottom: 100px;
        /* background-color: #585e0e; */
        background: linear-gradient(#9ba536, #585e0e);
        /* background: linear-gradient(#dce0b0, #585e0e); */
        border-radius: 10px;
        padding: 10px;
        width: 370px;
    }
}

@media screen and (max-width: 460px) {
    .corpo_das_informacoes form {
        margin-top: -40px;
        height: 500px;
    }

    .flex_form_user {
        grid-template-columns: 1fr;
        margin-left: 10px;
        width: 100%;
    }

    .editar_btn_user {
        margin-left: 0;
    }

    .flex_form_btn_user {
        width: 80%;
        margin-top: 150px;
    }

    .flex_form_user input, #select_genero_perfil {
        width: 100%;
    }
}


@media screen and (max-width: 450px) {
    section.meuPerfil {
        margin-top: 130px;
    }

    .h1_text_user {
        font-size: 19px;
    }

    .btn_voltar_info_compra {
        margin-left: 10px;
    }

    .backColor {
        transform: translateY(-90px);
        margin-bottom: 100px;
        background: linear-gradient(#9ba536, #585e0e);
        border-radius: 10px;
        padding: 15px;
        width: 340px;
    }

    h1#User-nome {
        margin-top: -17px;
    }

    .corpo_das_informacoes table th {
        font-size: 13px;
    }

    .corpo_das_informacoes table td {
        font-size: 13px;
    }

    .flex_form_btn_user {
        margin-top: 150px;
        /* margin-left: -90px; */
    }
}



@media screen and (max-width: 350px) {
    section.meuPerfil {
        margin-top: 120px;

    }

    .backColor {
        transform: translateY(-90px);
        margin-bottom: 100px;
        border-radius: 10px;
        padding: 5px;
        width: 310px;
    }
}


/*Responvisidade*/
/*perfil mostrando o nome do Usuario no cante superior direito da pagina*/
/*mais que comentario super explicativo e esse?*/


@media screen and (max-width: 990px) {
    .dp-menu {
        padding-left: 100px;
    }
}

@media screen and (max-width: 890px) {
    .dp-menu {
        padding-left: 300px;
    }
}

@media screen and (max-width: 800px) {
    .dp-menu {
        width: 290px;
        padding: 10px;
        margin-top: -55px;
        padding-left: 400px;
        z-index: 1000;
    }
}

@media screen and (max-width: 790px) {
    .dp-menu {
        margin-left: -360px;
    }
}

@media screen and (max-width: 490px) {
    .dp-menu {
        margin-right: 88px;
        /* background: black; */
    }

    .Laranja_dados_user {
        background-color: #b46639;
    }

    .flex_form_btn_user {
        display: inline-flexbox;
    }

}

@media screen and (max-width: 350px) {
    .card_filho2 .O_menu_do_usuario li {
        padding: 5px;
        margin: 0 10px;
    }
}



/*Formatacao da nova foto de perfil do usuario*/

.foto-perfil {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'foto-user nova-foto';
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    background: #585e0e;
    height: 50%;
    width: 75%;
    border: none;
    border-radius: 50%;
}

.foto-perfil {
    grid-area: foto-perfil;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 auto;
}

.nova-foto {
    grid-area: nova-foto;
    margin-top: 80px;
}

.foto-perfil input[type="file"] {
    display: none;
}

.cadastroP input[type="file"] {
    display: none;
}

label#pegar-nova-foto {
    /* height: 33px;
    width: 33px; */
    /* background: url(../../components/imgs/camera.png); */
    /* background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: -50px;
    margin-left: -50px;
    border: none;
    outline: none; */

}

.textoperfilFoto {
    font-size: 1.1rem;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    cursor: pointer;
}

a#pegar-nova-fotoPerfil {
    height: 33px;
    width: 33px;
    background: url(../../components/imgs/camera.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: -50px;
    margin-left: -50px;
    border: none;
    outline: none;
}


/**/

@media(max-width:1000px) {
    .foto-perfil {
        width: 50%;
        height: 50%;
    }
}

@media(max-width:800px) {
    .foto-perfil {
        margin-bottom: 50px;
    }
}


@media(max-width:475) {
    label#pegar-nova-foto {
        width: 100%;
    }
}

/*Nova formatacao do estilo do perfil do usuario*/
/*Formatacao para o ajuste da \imagem*/

.tent img {
    grid-area: foto-perfil;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 auto;
    padding: 10px;

    height: 37vh;
    width: 100%;
    border: none;
    border-radius: 50%;
}

@media(max-width:599px) {

    .tent img {
        height: 100%;
        width: 100%;
    }

}

.foto-perfil-cima img {
    width: 100px;
    height: 55px;
    border-radius: 50%;
}









/* Responsive table styles */
.responsiveTable {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.responsiveTable caption {
    font-size: 1rem;
    /* margin: 0.5rem 0 0.75rem; */
}

.responsiveTable tr {
    border-bottom: 1px solid #d1d1d161;
    /* padding: 0.5rem; */
}

.responsiveTable th,
.responsiveTable td {
    /* padding: 0.625rem; */
    text-align: left;
}

.responsiveTable th {
    font-size: 1rem;
    font-weight: 700;
    border-bottom: 2px solid #8f8f8f6e;
}

@media screen and (max-width: 777px) {

    .responsiveTable {
        border: 0;
    }

    td#fff {
        text-align: right;
    }

    .responsiveTable caption {
        font-size: 1rem;
    }


    .responsiveTable thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .responsiveTable tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625rem;
        padding: 0;
    }

    .responsiveTable td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 1rem;
        text-align: right;
    }

    .responsiveTable td::before {
        /*************************************************************************
        ** aria-label has no advantage, it won't be read inside a .responsiveTable
        content: attr(aria-label);================================================
        **************************************************************************/
        content: attr(data-label);
        float: left;
        font-weight: 100;
        text-transform: uppercase;
        font-size: 0.7rem;
    }

    .responsiveTable td:last-child {
        border-bottom: 0;
    }
}





td.qEp {
    text-align: right;
}
/* #inicio-loader{ */
/* position: absolute; */
/* background: #424616; */
/* background: #9ba236; */

/* color: white;
   width: 100%;
  height: 100%; 
  text-align: center;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center; */

/**/
/* margin-top: 190px; */
/* } */

#inicio-loader {
  background: #424616;
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;
}


.loader {
  width: 5rem;
  height: 5rem;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin-bottom: 3rem;
  background: url("../components/imgs/loading/logo2.png");
  background-position: center;
  background-size: cover;
}

/* .loader{
  width: 5rem;
  height: 5rem;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin-bottom: 3rem;
  /* border-top-color: #fff; */
/* animation: 1.4s spin infinite ease-in-out; */
/* margin-bottom: -500px; */
/* imgs\loading */
/* background: url("../components/imgs/loading/logo2.png");
  background-position: center;
  background-size: cover; */
/* }  */


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading>div {
  width: 1rem;
  height: 1rem;
  /* background: white; */
  border-radius: 50%;
  display: inline-block;
  animation: 1.5s bola-0 infinite ease-in-out both;
  margin: 0 auto;
}

.bola-0 {
  background: #9ba235;
  color: #fff;
  width: 14px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}

.bola-1 {
  background: #b46739;
  color: #fff;
  width: 14px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}

.bola-2 {
  background: #9ba235;
  color: #fff;
  width: 14px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}

.loading .bola-0 {
  animation-delay: -0.30s;
}

.loading .bola-1 {
  animation-delay: -0.15s;
}

@keyframes bola-0 {
  0%, 8%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

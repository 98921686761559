section.corpo {
    margin: 15px;
    display: flex;
}

.f_corpo {
    justify-content: space-around;
    align-items: center;
}

.fotografiaAnimal {
    margin: 50px;
}

img.img-animal {
    width: 500px;
    height: 300px;
    border-radius: 20px;
}

div.conteudo {
    width: 500px;
    height: 300px;
    background-color: #6e7504;
    border-radius: 20px;
    padding: 20px;
    color: #fff;
}

div.conteudo h2 {
    font-size: 20px;
    font-weight: bold;
}

div.conteudo p {
    font-size: 16px;
}

div.conteudo strong {
    color: #fff;
}

.legenda_do_lance p {
    margin: 10px;
}

div.tabela {
    border-radius: 30px;
    width: 95%;
    margin: 2%;
    height: 275px;
    background-color: #bdc466;
    overflow-y: hidden;
}

div.top {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 100%;
    background-color: #b46739;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

div.top h1 {
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}
div.hancking {
    display: flex;
    width: 90%;
    margin: 30px auto;
    transform: translateX(20px);
}

/* div.hancking:nth-child(1) .valor-licitador p {
    background-color: red;
} */

.hacking_img {
    width: 80px;
    position: absolute;
    z-index: 3;
}

img.foto-licitador {
    width: 100%;
    height: 80px;
    transform: translate(-20px, -20px);
}


div.nome-licitador {
    background-color: #9ba236;
    border-radius: 20px;
    width: 80%;
    z-index: 2;
}

.valor-licitador {
    width: 30%;
    border-radius: 0 20px 20px 0;
    background-color: #b46739;
    text-align: center;
    transform: translateX(-30px);
    z-index: 1;
}


.valor-licitador p {
    font-size: 1rem;
    transform: translateX(15px);
}

div.hancking p {
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    margin: 10px;
}

div.nome-licitador p {
    margin-left: 70px;
    font-size: 1rem;
}

section.lance {
    text-align: center;
}

div.card-lance {
    border-radius: 20px;
    margin: 50px auto;
    width: 400px;
    background-color: #6e7504;
    padding-bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.445);
}

div.barra-card-lance {
    background-color: #b46739;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.barra-card-lance p {
    font-size: 1.2em;
    font-family: Arial, Helvetica, sans-serif;
}

.input-lance {
    width: 90%;
    padding: 5px 10px;
    margin-top: 20px;
    border-radius: 30px;
    outline: none;
    border: #6e7705 3px solid;
    font-size: 18px;
    background-color: #bdc466;
    color: #fff;
}

.btn-lance {
    height: 30px;
    margin: 5px;
    padding: 0 10px;
    border-radius: 30px;
    border: #bdc466 2px solid;
    background-color: transparent;
    color: white;
    cursor: pointer;
}

.baixo_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.os_btn_lance {
    margin-right: 20px;
}

.btn_lance_cancelar {
    margin-right: 20px;
}

.select_post_lance {
    width: 90%;
    height: 30px;
    margin: 5px;
    padding: 0 10px;
    border-radius: 30px;
    border: #bdc466 2px solid;
    background-color: #bdc466;
    cursor: pointer;
    margin-top: 20px;
    outline: none;
}
.borda_primeiro{
    border-top: 2px solid #a56139;
    border-bottom: 2px solid #a56139;
}
.cor_primeiro{
    background-color:  #a56139;
    color: #6e7504;
}
@media (max-width: 800px) {
    div.nome-licitador {
        width: 70%;
    }

    .valor-licitador {
        width: 40%;
    }
}

@media (max-width: 750px) {
    .f_corpo {
        flex-direction: column;
    }

    .fotografiaAnimal {
        margin: 10px;
        width: 90%;
    }

    img.img-animal {
        width: 100%;
    }

    div.conteudo {
        width: 90%;
    }
}

@media (max-width: 600px) {
    div.nome-licitador {
        width: 60%;
    }

    .valor-licitador {
        width: 40%;
    }

    .valor-licitador p {
        font-size: .9rem;
    }

    div.nome-licitador p {
        font-size: .9rem;
    }

    .hacking_img {
        width: 60px;
    }

    img.foto-licitador {
        height: 60px;
        transform: translate(-20px, -10px);
    }
}

@media (max-width: 450px) {
    div.nome-licitador {
        width: 50%;
    }

    .valor-licitador {
        width: 50%;
    }

    .valor-licitador p {
        font-size: .7rem;
    }

    div.nome-licitador p {
        font-size: .7rem;
        margin-left: 40px;
    }

    .hacking_img {
        width: 50px;
    }

    img.foto-licitador {
        height: 50px;
        transform: translate(-15px, -7px);
    }

    div.card-lance {
        width: 90%;
    }

    img.img-animal {
        height: 200px;
    }
}

@media (max-width: 360px) {
    .valor-licitador p {
        font-size: .6rem;
    }

    div.nome-licitador p {
        font-size: .6rem;
    }

    .hacking_img {
        width: 45px;
    }

    img.foto-licitador {
        height: 45px;
        transform: translate(-15px, -5px);
    }

    img.img-animal {
        height: 180px;
    }
}

@media (max-width: 320px) {
    .valor-licitador p {
        font-size: .5rem;
    }

    div.nome-licitador p {
        font-size: .5rem;
    }

    .hacking_img {
        width: 40px;
    }

    img.foto-licitador {
        height: 40px;
        transform: translate(-15px, -5px);
    }
}



/*new change*/

/**/
@media (max-width: 570px) {
    h1.sugestao-para-licitadores {
        font-size: 1rem;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}


/**/
@media (max-width: 570px) {
    h1.sugestao-para-licitadores {
        font-size: 1rem;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}



/*Tempo restante*/
/*Tipografia da página dos lances*/
@media (max-width: 625px) {
    div h1#nome-leilao-fazenda_lote{
        color: #fff;
        margin-top: 30px;
        font-size: 1.1rem;
        font-weight: 100;
    }

    h1#txt-licitadores{
        font-size: 1.2rem;
    }

    h2#detalhes-animal{
        text-align: center;
    }
}

@media (max-width: 625px) {
    div h2#tempo-restante{
        color: #fff;
        margin: 2px;
        font-size: 1.3rem;
        font-weight: 300;
    }
}


/*formatacao do tempo de duracao de l;eilao*/


.duracao span{
    font-size: 1.5rem; 
    display: flex;
    justify-content: flex-end;
    margin-right: -400px;
} 


@media(max-width:1082px){
    .duracao span{
        font-size: 1rem; 
        display: flex;
        justify-content: center;
        margin: 0 auto;
    } 

    h1.sugestao-para-licitadores{
        font-size: 1rem;
    }
}

h1.sugestao-para-licitadores{
    font-size: 2rem;
}


@media(max-width:500px){
    .duracao span{
        font-size: 0.7rem; 
    }
    .tamanho-bom{
        font-size: 0.7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 10px;
    }
}



/**/
@media(max-width:360px){
    div.valor-preco-inicial-leilao{
        background: #6e7504;
    }

    div.dar-m{
        margin-top: 100px;
    }
}

/**/
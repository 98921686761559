  a {
      /* color: #1bbd36; */
      text-decoration: none;
  }

  a:hover {
      /* color: #2ae149; */
      text-decoration: none;
  }

  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  /* section {
    padding: 60px 0;
    overflow: hidden;
  } */

  /*--------------------------------------------------------------
  # Blog Gootstrap
  --------------------------------------------------------------*/
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      width: 100%;
      padding-right: var(--bs-gutter-x, .75rem);
      padding-left: var(--bs-gutter-x, .75rem);
      margin-right: auto;
      margin-left: auto
  }

  @media (min-width:576px) {
      .container, .container-sm {
          max-width: 540px
      }
  }

  @media (min-width:768px) {
      .container, .container-md, .container-sm {
          max-width: 720px
      }
  }

  @media (min-width:992px) {
      .container, .container-lg, .container-md, .container-sm {
          max-width: 960px
      }
  }

  @media (min-width:1200px) {
      .container, .container-lg, .container-md, .container-sm, .container-xl {
          max-width: 1140px
      }
  }

  @media (min-width:1400px) {
      .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
          max-width: 1320px
      }
  }

  .row {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(var(--bs-gutter-y) * -1);
      margin-right: calc(var(--bs-gutter-x)/ -2);
      margin-left: calc(var(--bs-gutter-x)/ -2)
  }

  .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x)/ 2);
      padding-left: calc(var(--bs-gutter-x)/ 2);
      margin-top: var(--bs-gutter-y)
  }

  .col-lg-8 {
      flex: 0 0 auto;
      width: 66.6666666667%
  }

  .d-flex {
      display: flex !important
  }

  .align-items-center {
      align-items: center !important;
  }

  /* Categoria */
  .col-lg-4 {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog {
      padding: 40px 0 20px 0;
  }
 

/*   
section.blog {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'entries sidebar';
    background: red;
}

.entries{
    grid-area: entries;
}

.sidebar{
    grid-area: sidebar;
} */





  .blog .entry {
      padding: 30px;
      margin-bottom: 60px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  .blog .entry .entry-img {
      max-height: 440px;
      margin: -30px -30px 20px -30px;
      overflow: hidden;
  }

  .blog .entry .entry-title {
      font-size: 28px;
      font-weight: bold;
      padding: 0;
      margin: 0 0 20px 0;
  }

  .blog .entry .entry-title a {
      color: #111;
      transition: 0.3s;
  }

  .blog .entry .entry-title a:hover {
      color: #1bbd36;
  }

  .blog .entry .entry-meta {
      margin-bottom: 15px;
      color: #777777;
  }

  .blog .entry .entry-meta ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      align-items: center;
      padding: 0;
      margin: 0;
  }

  .blog .entry .entry-meta ul li+li {
      padding-left: 20px;
  }

  .blog .entry .entry-meta i {
      font-size: 16px;
      margin-right: 8px;
      line-height: 0;
  }

  .eri, .view_eri {
      width: 100%;
      height: 70vh;
      /* background: url("../components/imgs/animal-6.jpg"); */
      background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url("../components/imgs/animal-6.jpg");
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .view_eri {
      height: 350px;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
  }

  .eri p, .view_eri p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2.2rem;
      z-index: 100;
      font-weight: 100;
      width: 60%;
      text-align: center;
  }

  .eri::before {
      content: '';
      position: absolute;
      top: 10;
      left: 0;
      background: #111111ae;
      width: 100%;
      height: 70vh;
  }

  .view_fundo_preto_cima {
      width: 100%;
      height: 100%;
      background: #111111ae;
      display: flex;
      justify-content: center;
      align-items: center;

  }

  .blog .entry .entry-meta a {
      color: #847872;
      font-size: 14px;
      display: inline-block;
      line-height: 1;
  }

  .blog .entry .entry-content p {
      line-height: 24px;
  }

  .blog .entry .entry-content .read-more {
      -moz-text-align-last: right;
      text-align-last: right;
  }

  .blog .entry .entry-content .read-more a {
      display: inline-block;
      background: #1bbd36;
      color: #fff;
      padding: 6px 20px;
      transition: 0.3s;
      font-size: 14px;
      border-radius: 4px;
  }

  .blog .entry .entry-content .read-more a:hover {
      background: #1ed33c;
  }

  .blog .entry .entry-content h3 {
      font-size: 22px;
      margin-top: 30px;
      font-weight: bold;
  }

  .blog .entry .entry-content blockquote {
      overflow: hidden;
      background-color: #fafafa;
      padding: 60px;
      position: relative;
      text-align: center;
      margin: 20px 0;
  }

  .blog .entry .entry-content blockquote p {
      color: #4d4643;
      line-height: 1.6;
      margin-bottom: 0;
      font-style: italic;
      font-weight: 500;
      font-size: 22px;
  }

  .blog .entry .entry-content blockquote::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: #111;
      margin-top: 20px;
      margin-bottom: 20px;
  }

  .blog .entry .entry-footer {
      padding-top: 10px;
      border-top: 1px solid #e6e6e6;
  }

  .blog .entry .entry-footer i {
      color: #5e5e5e;
      display: inline;
  }

  .blog .entry .entry-footer a {
      color: #1e1e1e;
      transition: 0.3s;
  }

  .blog .entry .entry-footer a:hover {
      color: #1bbd36;
  }

  .blog .entry .entry-footer .cats {
      list-style: none;
      display: inline;
      padding: 0 20px 0 0;
      font-size: 14px;
  }

  .blog .entry .entry-footer .cats li {
      display: inline-block;
  }

  .blog .entry .entry-footer .tags {
      list-style: none;
      display: inline;
      padding: 0;
      font-size: 14px;
  }

  .blog .entry .entry-footer .tags li {
      display: inline-block;
  }

  .blog .entry .entry-footer .tags li+li::before {
      padding-right: 6px;
      color: #6c757d;
      content: ",";
  }

  .blog .entry .entry-footer .share {
      font-size: 16px;
  }

  .blog .entry .entry-footer .share i {
      padding-left: 5px;
  }

  .blog .entry-single {
      margin-bottom: 30px;
  }

  .blog .blog-author {
      padding: 20px;
      margin-bottom: 30px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  .blog .blog-author img {
      width: 120px;
      margin-right: 20px;
  }

  .blog .blog-author h4 {
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 0px;
      padding: 0;
      color: #111;
  }

  .blog .blog-author .social-links {
      margin: 0 10px 10px 0;
  }

  .blog .blog-author .social-links a {
      color: rgba(17, 17, 17, 0.5);
      margin-right: 5px;
  }

  .blog .blog-author p {
      font-style: italic;
      color: #bfb9b6;
  }

  .blog .blog-comments {
      margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
      font-weight: bold;
  }

  .blog .blog-comments .comment {
      margin-top: 30px;
      position: relative;
  }

  .blog .blog-comments .comment .comment-img {
      margin-right: 14px;
  }

  .blog .blog-comments .comment .comment-img img {
      width: 60px;
  }

  .blog .blog-comments .comment h5 {
      font-size: 16px;
      margin-bottom: 2px;
  }

  .blog .blog-comments .comment h5 a {
      font-weight: bold;
      color: #4d4643;
      transition: 0.3s;
  }

  .blog .blog-comments .comment h5 a:hover {
      color: #1bbd36;
  }

  .blog .blog-comments .comment h5 .reply {
      padding-left: 10px;
      color: #111;
  }

  .blog .blog-comments .comment h5 .reply i {
      font-size: 20px;
  }

  .blog .blog-comments .comment time {
      display: block;
      font-size: 14px;
      color: #2b2b2b;
      margin-bottom: 5px;
  }

  .blog .blog-comments .comment.comment-reply {
      padding-left: 40px;
  }

  .blog .blog-comments .reply-form {
      margin-top: 30px;
      padding: 30px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  .blog .blog-comments .reply-form h4 {
      font-weight: bold;
      font-size: 22px;
  }

  .blog .blog-comments .reply-form p {
      font-size: 14px;
  }

  .blog .blog-comments .reply-form input {
      border-radius: 4px;
      padding: 10px 10px;
      font-size: 14px;
  }

  .blog .blog-comments .reply-form input:focus {
      box-shadow: none;
      border-color: #57e76f;
  }

  .blog .blog-comments .reply-form textarea {
      border-radius: 4px;
      padding: 7px;
      font-size: 14px;
      outline: none;
  }

  .blog .blog-comments .reply-form textarea:focus {
      box-shadow: none;
      border-color: #57e76f;
  }

  .blog .blog-comments .reply-form .form-group {
      margin-bottom: 25px;
  }

  .blog .blog-comments .reply-form .btn-primary {
      border-radius: 4px;
      padding: 10px 20px;
      border: 0;
      background-color: #111;
  }

  .blog .blog-comments .reply-form .btn-primary:hover {
      background-color: #1e1e1e;
  }

  .blog .blog-pagination {
      color: #444444;
  }

  .blog .blog-pagination ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
  }

  .blog .blog-pagination li {
      margin: 0 5px;
      transition: 0.3s;
  }

  .blog .blog-pagination li a {
      color: #111;
      padding: 7px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
      background: #1bbd36;
  }

  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
      color: #fff;
  }

  .blog .sidebar {
      padding: 30px;
      margin: 0 0 60px 20px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      background: white;
  }

  .blog .sidebar .sidebar-title {
      font-size: 20px;
      font-weight: 700;
      padding: 0 0 0 0;
      margin: 0 0 15px 0;
      color: #111;
      position: relative;
  }

  .blog .sidebar .sidebar-item {
      margin-bottom: 30px;
  }

  .blog .sidebar .search-form form {
      background: #fff;
      border: 1px solid #ddd;
      padding: 3px 10px;
      position: relative;
  }

  .blog .sidebar .search-form form input[type="text"] {
      border: 0;
      padding: 4px;
      border-radius: 4px;
      width: calc(100% - 40px);
  }

  .blog .sidebar .search-form form button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border: 0;
      background: none;
      font-size: 16px;
      padding: 0 15px;
      margin: -1px;
      background: #1bbd36;
      color: #fff;
      transition: 0.3s;
      border-radius: 0 4px 4px 0;
      line-height: 0;
  }

  .blog .sidebar .search-form form button i {
      line-height: 0;
  }

  .blog .sidebar .search-form form button:hover {
      background: #1ecf3b;
  }

  .blog .sidebar .categories ul {
      list-style: none;
      padding: 0;
  }

  .blog .sidebar .categories ul li+li {
      padding-top: 10px;
  }

  .blog .sidebar .categories ul a {
      color: #111;
      transition: 0.3s;
  }

  .blog .sidebar .categories ul a:hover {
      color: #1bbd36;
  }

  .blog .sidebar .categories ul a span {
      padding-left: 5px;
      color: #b4aca8;
      font-size: 14px;
  }

  .blog .sidebar .recent-posts .post-item+.post-item {
      margin-top: 15px;
  }

  .blog .sidebar .recent-posts img {
      width: 80px;
      float: left;
  }

  .blog .sidebar .recent-posts h4 {
      font-size: 15px;
      margin-left: 95px;
      font-weight: bold;
  }

  .blog .sidebar .recent-posts h4 a {
      color: #111;
      transition: 0.3s;
  }

  .blog .sidebar .recent-posts h4 a:hover {
      color: #1bbd36;
  }

  .blog .sidebar .recent-posts time {
      display: block;
      margin-left: 95px;
      font-style: italic;
      font-size: 14px;
      color: #b4aca8;
  }

  .blog .sidebar .tags {
      margin-bottom: -10px;
  }

  .blog .sidebar .tags ul {
      list-style: none;
      padding: 0;
  }

  .blog .sidebar .tags ul li {
      display: inline-block;
  }

  .blog .sidebar .tags ul a {
      color: #515151;
      font-size: 14px;
      padding: 6px 14px;
      margin: 0 6px 8px 0;
      border: 1px solid #c4c4c4;
      display: inline-block;
      transition: 0.3s;
  }

  .blog .sidebar .tags ul a:hover {
      color: #fff;
      border: 1px solid #1bbd36;
      background: #1bbd36;
  }

  .blog .sidebar .tags ul a span {
      padding-left: 5px;
      color: #aaaaaa;
      font-size: 14px;
  }

  /* View Noticias */
  .view_noticia_branca {
      background-color: #fff;
  }

  .view_noticia_letra_preta {
      color: #000;
  }

  .view_noticia_card_cinza {
      background-color: red;
  }


  /*Responsividade do blog*/

  @media (max-width:749px) {
    .eri p, .view_eri p {
        font-size: 1rem;
        width: 90%;
    } 
}


  @media (max-width:991px) {
 
    div.when-responsive{
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    div.when-responsive-1{
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
  }
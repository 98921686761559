html{
    scroll-behavior: smooth;
}
.h1_sobre_fazenda{
    color: #fff;
    text-align: center;
    margin: 20px 0;
    font-size: 30px;
}

.conteudo_fazenda{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ver_mapa_fazenda{
    padding: 5px 15px;
    border: 2px solid #fff;
    font-weight: bold;
    color: #fff;
    font-size: 17px;
    border-radius: 5px;
    background-color: #686d25;
    position: relative;
    bottom: 10px;
    transition: .3s;
    cursor: pointer
}
.ver_mapa_fazenda:hover{
    font-size: 18px;
    border-radius: 5px;
    background-color: #3f4217;
    color: #fff;
}
.maio_verificar_column {
    display: flex;
    flex-direction: column;
}

.maio_verificar_column>div:nth-child(1) {
}

.maio_verificar_column>div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.botao_verficar_user {
    background-color: #B46739;
    padding: 10px;
    width: 400px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    margin-top: 150px;
    border: none;
}

.bi1 {
    padding: 10px;
    width: 250px;
    height: 200px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    margin: 15px;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 0;
    border-radius: 30px;
    color: transparent;
    background-image: url('../../components/imgs/icons/bi1.svg');
    background-color: #B46739;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 30px;
    box-shadow: rgba(255, 255, 255, 0.268) 1px 1px 1px 1px;
}

.bi2 {
    padding: 10px;
    width: 250px;
    height: 200px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    margin: 15px;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 0;
    border-radius: 30px;
    color: transparent;
    background-image: url('../../components/imgs/icons/bi2.svg');
    background-color: #B46739;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 30px;
    box-shadow: rgba(255, 255, 255, 0.268) 1px 1px 1px 1px;
}
.botao_verficar_bi{
    display: none;
}

.submitbi{
    margin: 10%;
    background-color: #B46739;
    padding: 10px;
    width: 80%;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    border: none;
}
.pular{
    background-color: #739;
    padding: 10px;
    width: 80%;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    border: none;
   margin-left: 10%;

}

.fotoperfil{
    padding: 5px;
    width: 200px;
    height: 200px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 0;
    border-radius: 30px;
    background-image: url('../../components/imgs/icons/person-circle-outline.svg');
    background-color: #B46739;
    background-repeat: no-repeat;
    border-radius: 150px;
    background-size: cover;
    box-shadow: rgba(255, 255, 255, 0.268) 1px 1px 1px 1px;
}

.inputtext{
    width: 80%;
    height: 60px;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 0;
    border-radius: 30px;
    background-color: #B46739;
    box-shadow:  rgb(184, 184, 24) 1px 1px 1px 1px;
    border: 1px solid  rgb(184, 184, 24);
    color: white;
    font-size: large;
    text-align: center;
}


.center{
    text-align: center;
}
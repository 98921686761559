* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    overflow-x: hidden;
}

#borda_img {
    width: 100%;
    height: 280px;
    position: absolute;
    top: 190px;
    left: 0px;
    /* background-image: url('../components/imgs/borda_img.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.ver_loader {
    position: absolute;
    width: 300px;
    height: 300px;
    border: 10px solid red;
    border-radius: 50%;
    transform: translate(50%, -50%);
    z-index: 1000;
}

/*Login*/

.login_texto {
    color: #fff;
    padding: 20px;
    width: 100%;
    text-align: justify;
    margin: auto;
}

.cadastrar_responsive {
    display: flex;
    justify-content: center;
}

.login_texto h1 {
    color: #fff;
    text-align: center;
    font-size: 40px;
    margin: 30px 10px 50px 10px;
}

.p_s {
    justify-content: center;
}

.login,
.cadastro {
    margin-bottom: 100px;
    background-color: #999f55d3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.37);
    border-radius: 15px;
    width: 500px;
    padding-bottom: 50px;
}

.login input::placeholder {
    color: #fff;
}

.cadastro {
    width: 600px;
}

.text-imp {
    text-align: center;
    font-size: 14px;
}

.s_cima,
.c_cima,
.e_cima {
    background-color: #f3c332;
    border-radius: 15px 15px 0 0;
    justify-content: space-between;
    padding: 5px 10px 30px 5px;
}

.e_cima {
    padding: 10px;
    justify-content: center;
}

.i_cima {
    background-color: #f3c332;
    border-radius: 15px 15px 0 0;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.s_meio label {
    font-size: 17px;
    margin: 10px;
    margin-left: 20px;
}

.p_s span {
    margin: 20px;
}

/* .s_meio input,
select {
    padding: 5px;
    font-size: 17px;
    background-color: transparent;
    border: none;
    width: 80%;
    margin-right: 10px;
    outline: none;
    color: #fff;
    padding-left: 20px;
} */
.s_meio input,
select {
    padding: 5px;
    font-size: 17px;
    background-color: transparent;
    border: none;
    width: 100%;
    margin-right: 10px;
    outline: none;
    color: #fff;
    padding-left: 20px;
}
.selectNE{
    padding-left: 110px;
}

.selectNB{
    padding-left: 30px;
}

.selectPG{
    padding-left: 110px;
}

.s_meio select option {
    color: #000;
}

.s_pp {
    padding: 10px 0;
    border-bottom: 1px solid #758528;
}

.linha_baixo_visi {
    display: none;
    padding: 5px 0;
    border-bottom: 1px solid #758528;
}

.cadastro input::placeholder {
    color: #fff;
    font-weight: bold;
}

.login_texto input#place_input::placeholder {
    color: #dad7d7;
    font-weight: bold;
}

.s_bot {
    padding: 5px 10px;
    justify-content: space-between;
}

.s_btn {
    background-color: transparent;
    border-radius: 20px;
    padding: 5px 15px;
    border: 1px solid#9AA325;
    color: #fff;
    cursor: pointer;
    transition: .3s;
}

.s_btn:hover {
    background-color: #fff;
    border: 1px solid#9AA325;
    color: #9AA325;
}

.i_meio {
    padding: 10px;
}

.importante {
    width: 400px;
    margin-left: 30px;
    background-color: #999f55d3;
    margin-bottom: 220px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.37);
}

section.fundo_entrar_sobre,
section.fundo_entrar_fazenda {
    background-image: url('../components/imgs/CWAM/IMG_9705.jpg');
    background-position: center -240px;
    background-attachment: fixed;
    background-size: cover;
    height: 370px;
    max-width: 100%;
    margin-top: 5rem;
}

/* section.fundo_entrar_fazenda::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #758528;
    height: 370px;
    max-width: 100%;
} */

section.fundo_entrar_sobre::before,
section.fundo_entrar_fazenda::before {
    content: '';
    position: absolute;
    top: 1;
    left: 0;
    height: 370px;
    width: 100%;
    background: rgba(0, 0, 0, 0.289);
}

section.fundo_entrar_sobre,
section.fundo_entrar_fazenda {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 4;
}

section.fundo_entrar_fazenda {
    height: 400px;
}

section.fundo_entrar_fazenda::before {
    height: 400px;
}

/* Leilao Exclusivo Video */
#video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

}

.alert_campos {
    background-color: rgba(177, 54, 54, 0.572);
    font-weight: bold;
    letter-spacing: 1px;
    margin: 5px 20px;
    color: #fff;
    padding: 10px;
}

@media(max-width:650px) {
    .p_s {
        flex-direction: column;
    }

    .p_s>div {
        margin: auto;
    }

    .p_s>div:nth-child(2) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media(max-width:550px) {
    .login {
        width: 400px;
    }
}

@media(max-width:440px) {
    .login {
        width: 100%;
    }

    .importante {
        width: 100%;
    }

    .login_texto h1 {
        font-size: 30px;
        margin: 30px 5px 50px 5px;
    }

    .quebra_visitante {
        flex-direction: column;
    }

    .s_meio .quebra_visitante input {
        margin-right: 90px;
        width: 90%;
    }

    .linha_baixo_visi {
        display: block;
    }
}

@media(max-width: 650px) {
    .cadastro {
        width: 400px;
    }

    .p_s {
        margin-bottom: 100px;
    }
}

@media(max-width:440px) {
    .cadastro {
        width: 100%;
    }
}

.section {
    padding: 0;
}

.imgfundo {
    widows: 100%;
    height: 100vh;
    /* background-image: url('../components/imgs/animal-01.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.meio {
    padding: 20px;
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
}

.mm {
    width: 80%;
    transform: translateY(-150px);
}

.mms {
    width: 80%;
    transform: translateY(-150px);
}

.m_laranja {
    max-width: 250px;
    height: 200px;
    background-color: #80808053;
}

.m_img {
    margin-top: 40px;
    width: 150px;
    height: 150px;
    /* background-color: #686d25; */
}

.quadrado {
    max-width: 240px;
}

.verde {
    background-color: #99a325;
}

.verde_escuro {
    background-color: #686d25;
}

.tres {
    width: 80%;
    margin: auto;
    padding: 20px;
    transform: translateY(-50px);
}

.cima_tres {
    transform: translateY(-70px);
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    justify-content: center;
    padding: 0;
}

.ca {
    padding: 10px;
}

.tresimg {
    background-color: #758528;
    width: 157px;
    height: 150px;
    margin: auto;
    border-radius: 5px;
}

#tresimg1 {
    background-image: url('../components/imgs/missao.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    height: 100px;
    /* background-position: -67px, -50px; */
}

#tresimg2 {
    background-image: url('../components/imgs/visao.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    height: 100px;
}

#tresimg3 {
    background-image: url('../components/imgs/valor.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    height: 100px;
}

.tresletra {
    width: 270px;
    background-color: #758528;
    color: white;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
}

.ctres {
    min-height: 100px;
}

.vindo {
    height: 500px;
    /* background-image: url('../components/imgs/Cultura Coop_Huíla II.png'); */
    background-image: url('../components/imgs/CWAM/NovaCWAM/4.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    margin-top: -80px;
}

.ima {
    height: 500px;
}

.vindo_sombra {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 500px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vindo_letra {
    width: 500px;
    z-index: 1;
    color: #fff;
}

.vindo_letra h1 {
    font-size: 35px;
    text-align: center;
}

.vindo_letra p {
    margin: 50px 20px;
}

.btbt {
    margin: auto;
    width: 300px;
    display: flex;
    justify-content: center;
}

.vindo_letra .botao {
    text-align: center;
    margin: auto;
    background-color: #758528;
    border-radius: 20px;
    border: none;
    color: #fff;
}

.cent {
    width: 95%;
    margin: auto;
}

.coluna {
    display: flex;
    justify-content: center;
}

.car_cent {
    height: 500px;
    max-width: 350px;
}

.cc {
    background-repeat: no-repeat;
    background-size: cover;
}

.tts {
    background-color: #75852883;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.cg {
    width: 100%;
    transform: translateY(-70px);
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    justify-content: center;
    padding: 0;
}

.cg>div {
    margin: 10px;
    padding: 0;
    width: 300px;
    height: 620px;
    border-radius: 20px;
}

.c_img {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    z-index: 3;
}

.llr {
    position: absolute;
    width: 270px;
    transform: translateY(-50px);
    padding: 15px;
    margin-left: 10px;
    text-align: justify;
}

.laranja {
    color: #f3c332;
}

.c_baixo {
    transform: translateY(-30px);
    font-size: 20px;
}

.c_img1 {
    background-image: url('../components/imgs/INTERFACE\ COOPERATIVA\ IIIai-08.png');
}

.c_img2 {
    background-image: url('../components/imgs/INTERFACE\ COOPERATIVA\ IIIai-09.png');
}

.c_img3 {
    background-image: url('../components/imgs/INTERFACE\ COOPERATIVA\ IIIai-10.png');
}


/* Sobre */

.sobre_texto,
.sobre_texto2 {
    color: #fff;
    padding: 5px;
    width: 90%;
    text-align: justify;
    margin: auto;
}



.sobre_texto h1 {
    font-size: 30px;
    text-align: center;
}

.sobre_texto2 h1 {
    font-size: 40px;
    text-align: center;
}

.t2 {
    padding-bottom: 100px;
}

.sobre_texto .texto2 {
    font-size: 20px;
    text-align: center;
    margin-top: 40px;
}

.sobre_texto .texto {
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 30px;
}

p.texto {
    color: white;
    text-align: left;
}

.dividir {
    height: 300px;
    background-color: #75852883;
}

.dividir2 {
    height: 100px;
    background-color: #75852883;
}

.img_chefe {
    width: 300px;
    height: 300px;
    background-image: url('../components/imgs/chefe.png');
    background-repeat: no-repeat;
    background-size: cover;
    border: 8px solid #595e1f;
    margin: auto;
    border-radius: 40px;
    transform: translateY(-150px);
}

#mapa {
    background-color: #686d25;
    height: 400px;
}

.faz_flex {
    display: flex;
}

/* --------------- Sobre Piramide ----------------------- */
.filho_piramide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.neto_priramide {
    margin: 20px;
}

.neto_priramide>div .img_piramide1 {
    background-color: #a7ac69;
    width: 150px;
    height: 150px;
    background-image: url('../components/imgs/PIRAMIDE/1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #595e1f;
    border-radius: 10px;
}

.neto_priramide>div .img_piramide_avatar {
    background-color: #a7ac69;
    width: 150px;
    height: 150px;
    background-image: url('../components/imgs/CWAM/do-utilizador.png');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #595e1f;
    border-radius: 10px;
}

.neto_priramide>div .img_piramide2 {
    background-color: #a7ac69;
    width: 150px;
    height: 150px;
    background-image: url('../components/imgs/PIRAMIDE/2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #595e1f;
    border-radius: 10px;
   
}

.neto_priramide>div .img_piramide3 {
    background-color: #a7ac69;
    width: 150px;
    height: 150px;
    background-image: url('../components/imgs/PIRAMIDE/3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #595e1f;
    border-radius: 10px;
}

.neto_priramide>div .img_piramide4 {
    background-color: #a7ac69;
    width: 150px;
    height: 150px;
    background-image: url('../components/imgs/PIRAMIDE/4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #595e1f;
    border-radius: 10px;
}

.neto_priramide>div .img_piramide5 {
    background-color: #a7ac69;
    width: 150px;
    height: 150px;
    background-image: url('../components/imgs/PIRAMIDE/5.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #595e1f;
    border-radius: 10px;
}

.neto_priramide>div .img_piramide6 {
    background-color: #a7ac69;
    width: 150px;
    height: 150px;
    background-image: url('../components/imgs/PIRAMIDE/6.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #595e1f;
    border-radius: 10px;
}

@media(max-width:800px) {

    .neto_priramide>div .mais {
        transform: translateX(-35px);
    }

    .neto_priramide>div .img_piramide2 {
        background-color: #a7ac69;
        width: 140px;
        height: 140px;
        margin: 5px;
        transform: translateX(-30px);
    }

    .neto_priramide>div .img_piramide3 {
        background-color: #a7ac69;
        width: 140px;
        height: 140px;
        margin: 5px;
        transform: translateX(-30px);
    }

    .neto_priramide>div .img_piramide4 {
        background-color: #a7ac69;
        width: 125px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transform: translateX(20px);
    }

    .neto_priramide>div .img_piramide5 {
        background-color: #a7ac69;
        width: 125px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transform: translateX(20px);
    }

    .neto_priramide>div .img_piramide6 {
        background-color: #a7ac69;
        width: 125px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transform: translateX(20px);
    }
}

.neto_priramide>div .legenda_piramide {
    border-radius: 10px;
    color: #fff;
    margin-top: 20px;
    background-color: #a7ac69;
    padding: 10px;
    font-size: 18px;
    text-align: center;
    margin: 5px;
    /* width: 200px; */
}

.neto_priramide>div .legenda_piramideVice {
    border-radius: 10px;
    color: #fff;
    margin-top: 20px;
    background-color: #a7ac69;
    padding: 5px;
    font-size: 18px;
    text-align: center;
    margin: 5px;
    width: 140px;
}

#legenda-J {
    width: 230px;
}

.piramide {
    transform: translateY(-120px);
}

.pai_quadrado_piramide1 {
    display: grid;
    place-items: center;
}

/* .pai_quadrado_piramide2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    width: 60%;
    margin: 0 auto;
} */
.pai_quadrado_piramide2 {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    width: 60%;
    justify-content: space-between;
    margin: 0 auto; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    width: 60%;
    justify-content: space-between;
    margin: 0 auto;
    gap: 10px;
}


.pai_quadrado_piramide3 {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    place-items: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    transform: translateX(-10px); */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    width: 60%;
    justify-content: space-between;
    /* margin: 0 auto; */
    gap: 10px;
}

.pai_quadrado_piramide3>div {
    margin-right: 10px;
}

/* -------------------------------------- */


@media(max-width: 1100px) {
    .neto_priramide>div .img_piramide {
        width: 180px;
        height: 180px;
    }
}

@media(max-width: 900px) {
    .m_img {
        margin-top: 40px;
        width: 100px;
        height: 100px;
        /* background-color: #686d25; */
    }


    /*Tornando as imagens dos cards com o temanho certo*/

    #tresimg1 {
        width: 50px;
        height: 50px;
    }

    #tresimg2 {
        width: 50px;
        height: 50px;
    }

    #tresimg3 {
        width: 50px;
        height: 50px;
    }

    /*Fim*/

    .m_laranja {
        max-width: 180px;
        height: 150px;
        /* background-color: #b46639be; */
    }

    .m_letra {
        font-size: 13px;
    }

    .quadrado {
        max-width: 180px;
    }

    .mm {
        width: 90%;
        transform: translateY(-120px);
    }
}

@media(max-width: 960px) {
    .cg>div {
        margin: 6px;
        width: 243px;
        height: 500px;
    }

    .llr {
        width: 220px;
        transform: translateY(-45px);
        font-size: 14px;
    }

    .c_baixo {
        transform: translateY(-22px);
        font-size: 18px;
    }
}

@media(max-width: 900px) {
    #borda_img {
        height: 200px;
        top: 150px;
    }

    .mms {
        width: 90%;
        transform: translateY(-120px);
    }
}

@media(max-width: 870px) {
    .mm {
        transform: translateY(-150px);
    }
}

@media(max-width: 820px) {
    .mm {
        transform: translateY(-160px);
    }
}

@media(max-width: 800px) {
    #borda_img {
        height: 150px;
        top: 100px;
    }

    .mm {
        transform: translateY(-170px);
    }

    .piramide {
        transform: translateY(-100px);
    }

    .neto_priramide>div .legenda_piramide {
        font-size: 16px;
        text-align: center;
        width: 100%;
    }

    .neto_priramide>div .img_piramide {
        width: 160px;
        height: 160px;
    }
}

@media(max-width: 780px) {
    .cg>div {
        width: 183px;
        height: 390px;
    }

    .llr {
        width: 180px;
        transform: translateY(-26px);
        font-size: 11px;
    }

    .c_baixo {
        transform: translateY(-18px);
        font-size: 14px;
    }

    .mm {
        transform: translateY(-190px);
    }
}

@media(max-width: 750px) {
    .mm {
        transform: translateY(-210px);
    }
}

@media(max-width: 700px) {
    .sobre_texto2 h1 {
        font-size: 25px;
    }

    .piramide {
        transform: translateY(-90px);
    }

    .pai_quadrado_piramide3 {
        transform: translateX(-20px);
    }

    .neto_priramide>div .img_piramide {
        width: 140px;
        height: 140px;
    }

    .neto_priramide>div .legenda_piramide {
        font-size: 13px;
        text-align: center;
    }
}

@media(max-width: 690px) {
    .m_img {
        margin-top: 40px;
        width: 80px;
        height: 80px;
        /* background-color: #686d25; */
    }

    .m_laranja {
        max-width: 150px;
        height: 130px;
        /* background-color: #b46639be; */
    }

    .m_letra {
        font-size: 11px;
    }

    .quadrado {
        max-width: 150px;
    }

    #tresimg1 {
        width: 50px;
        height: 50px;
    }

    #tresimg2 {
        width: 50px;
        height: 50px;
    }

    #tresimg3 {
        width: 50px;
        height: 50px;
    }

    .mm {
        transform: translateY(-210px);
    }

    .mms {
        width: 90%;
        transform: translateY(-110px);
    }
}

@media(max-width: 650px) {
    #borda_img {
        height: 120px;
        top: 90px;
    }

    .piramide {
        transform: translateY(-80px);
    }

    .neto_priramide>div .legenda_piramide {
        font-size: 11px;
        text-align: center;
    }

    .neto_priramide>div .img_piramide {
        width: 120px;
        height: 120px;
        border-radius: 10px;
    }
}

@media(max-width: 640px) {
    .cg {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
    }

    p.texto li {
        font-size: 1rem;
        padding-left: 10px;
    }

    .cg>div {
        width: 273px;
        height: 560px;
    }

    .llr {
        width: 250px;
        transform: translateY(-65px);
        font-size: 14px;
    }

    .c_baixo {
        transform: translateY(-25px);
        font-size: 18px;
    }
}

@media(max-width: 590px) {

    #legenda-J {
        width: 100%;
    }

    .m_img {
        margin-top: 40px;
        width: 70px;
        height: 70px;
        /* background-color: #686d25; */
    }

    .m_laranja {
        max-width: 130px;
        height: 120px;
        /* background-color: #b46639be; */
    }

    .m_letra {
        font-size: 9px;
    }

    .quadrado {
        max-width: 130px;
    }

    .mm {
        width: 100%;
        transform: translateY(-110px);
    }
}

@media(max-width:560px) {
    .cg {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
    }

    .cg>div {
        width: 223px;
        height: 480px;
    }

    .llr {
        width: 210px;
        transform: translateY(-40px);
        font-size: 13px;
    }

    .c_baixo {
        transform: translateY(-22px);
        font-size: 17px;
    }
}

@media(max-width:525px) {
    .neto_priramide>div .legenda_piramide {
        font-size: 9px;
        text-align: center;
    }

    .neto_priramide>div .img_piramide {
        width: 100px;
        height: 100px;
    }
}

@media(max-width:500px) {
    .sobre_texto2 h1 {
        font-size: 20px;
    }

    .cg {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
    }

    .cg>div {
        width: 193px;
        height: 410px;
        margin: auto;
    }

    .llr {
        width: 180px;
        transform: translateY(-30px);
        font-size: 11px;
    }

    .c_baixo {
        transform: translateY(-19px);
        font-size: 15px;
    }

    .piramide {
        transform: translateY(-70px);
    }
}

@media(max-width: 480px) {
    #borda_img {
        height: 100px;
        top: 80px;
    }
}

@media(max-width: 460px) {
    .m_img {
        margin-top: 30px;
        width: 60px;
        height: 60px;
        /* background-color: #686d25; */
    }

    .m_laranja {
        max-width: 130px;
        height: 100px;
        /* background-color: #b46639be; */
    }

    .m_letra {
        font-size: 7px;
    }

    .quadrado {
        max-width: 130px;
    }

    .mm {
        width: 100%;
        transform: translateY(-90px);
    }

    .piramide {
        transform: translateY(-65px);
    }

    .neto_priramide>div .legenda_piramide {
        font-size: 7px;
        text-align: center;
    }

    .pai_quadrado_piramide3 {
        transform: translateX(-15px);
    }

    .neto_priramide>div .img_piramide {
        width: 90px;
        height: 90px;
    }
}

@media(max-width: 400px) {
    #borda_img {
        height: 90px;
        top: 90px;
    }

    .piramide {
        transform: translateY(-60px);
    }

    .neto_priramide>div .legenda_piramide {
        font-size: 6px;
        padding: 4px;
        border-radius: 4px;
        text-align: center;
        margin-top: 5px;
    }

    .pai_quadrado_piramide3 {
        transform: translateX(-10px);
    }

    .neto_priramide>div .img_piramide {
        width: 80px;
        height: 80px;
        border-width: 1px;
    }
}


@media(max-width:430px) {
    .cg {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 5px;
    }

    .cg>div {
        width: 293px;
        height: 600px;
    }

    .llr {
        width: 270px;
        transform: translateY(-58px);
        font-size: 16px;
    }

    .c_baixo {
        transform: translateY(-30px);
        font-size: 17px;
    }

    .vindo {
        height: 600px;
    }

    .ima {
        height: 600px;
    }

    .vindo_sombra {
        height: 600px;
    }
}

@media(max-width: 362px) {
    .piramide {
        transform: translateY(-50px);
    }

    .neto_priramide>div .legenda_piramide {
        font-size: 5px;
        text-align: center;
    }

    .pai_quadrado_piramide3 {
        transform: translateX(-10px);
    }

    .neto_priramide>div .img_piramide {
        width: 70px;
        height: 70px;
        border-width: 3px;
    }
}

@media(max-width: 320px) {
    .neto_priramide>div .legenda_piramide {
        font-size: 5px;
    }

    .pai_quadrado_piramide3 {
        transform: translateX(0px);
    }

    .neto_priramide>div .img_piramide {
        width: 60px;
        height: 60px;
        border-width: 2px;
    }
}

@media(max-width: 375px) {
    .video {

        width: 4px;

    }
}

@media(max-width: 400px) {
    #txt-inicio-sobre {
        font-size: 1.4rem;
        font-weight: 100;
    }

    .card-missao {
        margin: 12px;
    }
}

/*Ajustando a margem entre os cards explicativos da M,V,V*/
/*Colocando margens entre os cards*/

@media(max-width: 475px) {
    .card-missao {
        margin: 6px;
    }
}

@media(max-width: 962px) {
    .card-missao {
        margin: 6px;
    }
}

/* Rodapé */

section.inf-contato {
    background: #f3c332;
    color: white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

div#contato table {
    background: #f3c332;
}

div#contato h1 {
    color: white;
}

.column h2,
p {
    color: white;
}

.column a.button:hover {
    box-shadow: inset 250px 0 0 #f3c332;
    color: white;
    transition: 0.5s ease-in;
}

.columns footer {
    display: grid;
    justify-content: center;
    align-items: center;
    background: coral;
}

section.inf-contato img#img_logo {
    width: 10%;
    height: 13vh;
    background: #758528;
    border-radius: 50%;
}

section.inf-contato h1 {
    text-align: center;
    margin: 0 auto;
    font-family: Arial, Helvetica, sans-serif;
}

section.inf-contato #contato {
    display: grid;
    justify-content: center;
    align-items: center;
}

section.inf-contato input,
section.inf-contato textarea {
    background: #ffebe086;
    border: #f3c332;
    color: white;
}

footer.footer {
    background: #f3c332;
    height: 100%;
    width: 100%;
}

footer .redes_sociais {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

footer .redes_sociais img {
    height: 5vh;
    max-width: 100%;
    margin: 5px;
    transition: 0.3s;
    cursor: pointer;
}

footer .redes_sociais img:hover {
    padding-top: 15px;
}

footer .texto {
    width: 600px;
    margin: 0 auto;
}

footer .texto p {
    color: white;
}

div#atendimento {
    background: #758528;
    height: 6.9vh;
    width: 290px;
    border: 2px solid white;
    border-radius: 24px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#atendimento img {
    width: 6%;
    height: 13vh;
    margin-left: 19rem;
    position: absolute;
    border: 2px solid white;
    border-radius: 50%;
}


.segmentacao {
    display: grid;
    margin: 10px;
    padding: 12px;
    background: #758528;
    border-radius: 7px;
}

.segmentacao h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    color: #fff;
}

.ProdutosCWAM {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
}

@media(max-width:800px) {
    .ProdutosCWAM {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:600px) {
    .ProdutosCWAM {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:500px) {
    .ProdutosCWAM {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:400px) {
    .ProdutosCWAM {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }
}

.produtoCWAM1 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/produto1.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM2 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/produto2.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM3 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/produto3.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM4 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/produto4.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM5 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/5.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM6 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/4.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM7 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/12.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM8 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/1.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM9 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/10.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM10 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/9.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM11 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/10.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM11 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/feijao.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.produtoCWAM12 {
    background: url(../components/imgs/CWAM/NovaCWAM/produtosCWAM/2.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    height: 155px;
    width: 155px;
}

.Fundo_produtoCWAM4 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: #dddddd86;
    padding: 10px;
    margin: 4px;
    border-radius: 7px;
}

/* .MeioContent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: -150px;
} */

.NovosCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'NovosCards_Noticia NovosCards_Produtos';
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    /* background: red; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.NovosCards_Noticia {
    grid-area: NovosCards_Noticia;

    width: 100%;
    background-image: url('../components/imgs/INTERFACE\ COOPERATIVA\ IIIai-08.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    height: 200px;
}

.NovosCards_Produtos {
    grid-area: NovosCards_Produtos;
    width: 100%;
    background-image: url('../components/imgs/INTERFACE\ COOPERATIVA\ IIIai-09.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    height: 200px;
}


.PaiCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;
    width: 60%;
    padding: 10px;
}

@media (max-width: 550px) {
    .PaiCards {
        grid-template-columns: 1fr;
    }
}

.FilhoCard {
    height: 100%;
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.FilhoCard img {
    width: 100%;
    height: 370px;
    object-fit: cover;
    border-radius: 8px;
}

.FilhoCard h4 {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
}

.FundoDescricao {
    margin-top: 30px;
}

.FundoDescricao p {
    text-align: justify;
}

.FilhoCard ul {
    padding: 0;
    margin: 0;
}

.FilhoCard li {
    list-style: none;
    font-size: 1rem;
    margin-bottom: 5px;
}

.btnCard {
    margin-top: auto;
    margin-top: 20px;
}

.btnCard button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 300px;
    padding: 15px;
    background: #595e1f;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    color: #eee;
    border: none;
    border-radius: 7px;
}

.IMG_Noticia {
    background: url(../components/imgs/CWAM/NovaCWAM/1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

.IMG_Produtos {
    background: url(../components/imgs/CWAM/IMG_9717.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

h2.parceiros {
    font-size: 2.3rem;
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px;
}


.EspacoVideo {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 2px;
    background: #595e1f;
    border-radius: 7px;
    margin-bottom: 100px;
}

.videoPP {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

@media(max-width:920px) {
    .EspacoVideo {
        width: 100%;
        height: 100%;
    }

    .NoVideo {
        display: flex;
        max-width: 100%;
        max-width: 100%;
    }

    .section.video {
        margin-bottom: 0;
    }

    .videoPP {
        min-width: 100%;
        min-height: 100%;
    }
}



.PP1 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/1caixaAngola.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

.PP2 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/2minfin4073308.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 150px;
    height: 110px;
    border-radius: 10px;
}

.PP3 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/3logo-nossa-seguros.png);
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover;
    width: 110px; */
    background-size: 100% 100%;
    width: 150px;
    height: 110px;
    border-radius: 10px;
}

.PP4 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/4images.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

.PP5 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/5fgc.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

.PP6 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/6bancoYuto.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

.PP7 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/7BAI.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

/* .PP8 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/bancoYeto.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
} */

/* .PP9 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/policia.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
} */

/* .PP10 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/LOGOFENA.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
} */

/* .PP11 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url(../components/imgs/CWAM/parceiros/dvp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 110px;
    height: 110px;
    border-radius: 10px;
} */

.containerParceiros {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
}

.cardParceiro {
    flex: 1 1 120px;
    /* Define a largura mínima e máxima de 200px */
    margin: 5px;
    padding: 5px;
    background-color: #f0f0f000;
    border-radius: 5px;
}

/* Define as regras para telas menores */
@media (max-width: 768px) {
    .cardParceiro {
        flex-basis: calc(50% - 20px);
        /* 2 cards por linha */
    }

}


@media(min-width: 360px) and (max-width: 768px){
    .neto_priramide>div .legenda_piramide {
        font-size: 18px;
        /* width: 200px; */
        font-weight: 200;
    }
    .pai_quadrado_piramide2 {
        width: 100%;
        gap: 40px;
    }
    .neto_priramide>div .menos {
        transform: translateX(15px);
    }
    .neto_priramide>div .mais {
        transform: translateX(-30px);
    }

    .pai_quadrado_piramide3 {
        width: 10%;
        grid-template-columns: 1fr 1fr;
    }

    /* VISI */
    .selectNE{
        padding-left: 0px;
    }
    .selectNB{
        padding-left: 0px;
    }
    
        .selectPG{
            padding-left: 0px;
    }
    /* NOTICIA */
    #navigateP{
        margin-bottom: 40px;
    }
    section.fundo_entrar_sobre,
section.fundo_entrar_fazenda {
    background-image: url('../components/imgs/CWAM/IMG_9705.jpg');
    background-position: center -240px;
    background-attachment: fixed;
    background-size: cover;
    height: 370px;
    max-width: 100%;
    margin-top: 5rem;
}

/* section.fundo_entrar_fazenda::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #758528;
    height: 370px;
    max-width: 100%;
} */


}


@media (width: 414px) {
    .precoCompraProduto{
        margin-top: -10px;
        margin-left: 390px;
        color: "#B77549";
        background:red;

}
}


@media (max-width: 768px) {
    .cardParceiro {
        flex-basis: calc(50% - 20px);
        /* 2 cards por linha */
    }

}

.error-text{
    color: #ec1010d3;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
}


.precoCompraProduto{
    margin-top: -30px;
    margin-left: 340px;
    color: "#B77549";
    background:red;
}
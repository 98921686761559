/* header {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100px;
    background-color: #9ba236;
}

.logo {
    text-decoration: none;
    color: white;
    padding-left: 10px;
}

img.img_logo {
    height: 100px;
    width: 230px;
    margin: 0 auto;
    padding-left: 20px;
}

.buscar {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-left: 240px;
}

.buscar input {
    width: 290px;
    padding: 10px;
    margin: 50px;
    margin-top: 40px;
    display: grid;
    justify-content: center;
    align-items: center;
    border: 3px solid #7b8122;
    border-radius: 20px;
    outline: none;
    font-size: 0.9rem;
}

.columns#menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    margin-top: -50px;
}

.content_nav li {
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    margin: 0 auto;
    z-index: 10;
}

.content_nav li .item {
    text-decoration: none;
    color: white;
    position: relative;
    font-size: 1.2rem;
    margin-top: -9%;
}

.content_nav li .item:hover {
    text-decoration: none;
    color: #b46739;
    position: relative;
}

.content_nav li .item::after {
    content: '';
    display: block;
    height: 2px;
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    transform-origin: right;
    transform: scale(0, 1);
    transition: transform ease-in-out 0.5s;
}

.content_nav li .item:hover::after {
    transform: scale(1, 1);
    color: #b46739;
}

.sub_menu-1 {
    display: none;
}

ul.content_nav li:hover .sub_menu-1 {
    display: block;
    position: absolute;
    background-color: #9ba236;
    margin-top: 0px;
    margin-left: -50px;
    padding: 5px;
}

ul.content_nav li:hover .sub_menu-1 ul {
    display: flex;
    flex-direction: column;
}

ul.content_nav li:hover .sub_menu-1 ul li {
    background-color: #585e0e;
    padding: 10px;
    margin: 1px;
    width: 150px;
    text-align: center;
    transition: 0.7s ease-in;
}

ul.content_nav li:hover .sub_menu-1 ul li:hover {
    background-color: #585e0e;
    padding: 10px;
}


/*Formatação da Parte Responsiva do Menu*/
/* 
@media screen and (max-width: 900px) {
    #nosso-menu {
        width: 100%;
        background: #9ba236;
        height: 160px;
    }
    .buscar input {
        margin-right: 30px;
    }
}

@media screen and (max-width: 800px) {
    header {
        height: 100px;
    }
    #nosso-menu {
        width: 100%;
        background: #9ba236;
        height: 120px;
    }
}

@media screen and (max-width: 800px) {
    .menu-mobile .content_nav {
        display: none;
        width: 100%;
    }
    .buscar input {
        display: none;
    }
    .logo {
        text-decoration: none;
        color: white;
        padding-left: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
    img.img_logo {
        height: 100px;
        width: 200px;
    }
}


/*Menu Burger*/

.nosso-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10px 0px;
    background-color: red;
}


/*
.tab-nav{
    outline: none;
    display: none;
}

.label{
    display: none;
    color: white;
}

.burger{
    width: 30px;
    height: 4px;
    background-color: white;
    margin-top: 5px; 
}
*/
/* 
@media screen and (max-width: 800px) {
    .label {
        display: grid;
        cursor: pointer;
        grid-column: 3;
        padding-right: 10px;
    }
    .menu-mobile .content_nav {
        display: grid;
        grid-template-columns: 1fr;
        grid-column: 1/4;
        text-align: center;
        max-height: 0;
        overflow: hidden;
        transition: all ease-in-out 0.6s;
    }
    .menu-mobile .content_nav {
        max-height: 500px;
    }
    .content_nav li {
        padding: 15px 0px;
    }
    ul.content_nav li:hover .sub_menu-1 {
        display: block;
        position: relative;
        background-color: #9ba236;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }
    ul.content_nav li:hover .sub_menu-1 ul {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin: 0 auto;
    }
    ul.content_nav li:hover .sub_menu-1 ul li {
        background-color: #585e0e;
        padding: 10px;
        width: 150px;
        text-align: center;
        transition: 0.7s ease-in;
    }
    ul.content_nav li:hover .sub_menu-1 ul li:hover {
        background-color: #585e0e;
        padding: 10px;
    }
}
 */

/**/
/*
.mobile-menu-icon {
    display: none;
}

.mobile-menu-icon img {
    position: absolute;
    top: 20px;
    right: 20px;
}

.menu-mobile {
    display: none;
} */
/*
@media screen and (max-width:800px) {
    .nosso-menu {
        padding: 1.5rem 4rem;
    }
    .content_nav {
        display: none;
    }
    .mobile-menu-icon {
        display: block;
    }
    .mobile-menu-icon button {
        background: transparent;
        border: none;
        cursor: pointer;
    }
    .menu-mobile ul {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .menu-mobile .content_nav {
        display: flex;
        padding-top: 5rem;
        background: #9ba236;
        transition: 2s ease-in;
    }
    .open {
        display: block;
    }
} */

.chips_pesquisa {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.chips_pesquisa>div {
    font-size: 12px;
    border: 2px solid #b46739;
    background-color: #f7ede7;
    margin: 2px;
    border-radius: 10px;
    padding: 2px 5px;
    cursor: pointer;
}

.estilo_botao_pesquisa_input {
    display: flex;
    flex-direction: column;
}

/* .estilo_botao_pesquisa_input input:focus ~ .estilo_botao_pesquisa_corpo {
    display: block;
}

.estilo_botao_pesquisa_corpo:focus ~ .estilo_botao_pesquisa_corpo {
    display: block;
} */

.estilo_botao_pesquisa_corpo {
    background-color: #fff;
    padding: 10px;
    padding-top: 30px;
    width: 280px;
    margin-top: -60px;
    border-radius: 0 0 20px 20px;
    z-index: 13;
    /* display: none; */
}

.categoria_pesq li {
    margin: 10px 0;
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
}

.categoria_pesq h1 {
    margin: 10px 0;
    font-size: 11px;
    color: #888;
}
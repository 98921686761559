* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Times New Roman", sans-serif;
}

div#atendimento-whatsapp img {
    width: 300px;
    height: 130px;
    position: absolute;
    right: 30px;
    transform: translateY(-35px);
}


/*posicionando a imagem do atendimento whats*/

@media (max-width:888px){
    div#atendimento-whatsapp{
        margin-top: 60px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    section#contato{
        margin-top: 300rem;
        background: red;
    }
    
}

/*Fim da formatacao*/

section#contato {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    /* background: #7e5602;  */
    background: linear-gradient(50deg, #856e28, #fbd35b);
}

section#contato form {
    width: 100%;
}

h1#texto-1 {
    color: white;
    font-size: 2rem;
    text-align: center;
    margin-top: 80px;
}

h2#texto-2 {
    color: white;
    font-size: 1.5rem;
}

p.paragrafo-1 {
    color: white;
}

p.paragrafo-2 {
    color: white;
    text-align: right;
}

section#contato input {
    padding: 10px;
    margin: 4px;
    display: flex;
    flex-direction: column;
    border: 2px solid #a3633e;
    border-radius: 10px;
    background: #ffffff7a;
    color: white;
    font-size: 1rem;
    outline: none;
    width: 100%;
}

section#contato textarea {
    padding: 15px 0 0 15px;
    background: #ffffff7a;
    color: white;
    font-size: 1rem;
    border: 1px solid #7e5602;
    border-radius: 10px;
    outline: none;
    width: 100%;
    height: 140px;
    margin-left: 20px;
}

footer.footer {
    background: linear-gradient(50deg, #856e28, #fbd35b);
    height: 100%;
    width: 100%;
}

footer#rodape {
    background: linear-gradient(50deg, #fbd35b, #856e28);
    margin: 0 auto;
    padding: 20px;
    margin-top: -30px;
}

footer .redes_sociais {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

footer .redes_sociais img {
    height: 30px;
    width: 40px;
    margin: 5px;
    transition: 0.3s;
    cursor: pointer;
}

.texto-rodape {
    margin: 0 auto;
    width: 100%;
}

.texto-rodape p {
    color: white;
    text-align: center;
}

.column#coluna-1 {
    grid-area: coluna-1;
}

.column#coluna-2 {
    grid-area: coluna-2;
}

.fale {
    width: 60%;
    margin: auto;
}

.ladoss {
    width: 90%;
    margin: auto;
}

.botao_enviar {
    width: 100%;
    margin-left: 20px;
    padding: 5px;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
}

@media screen and (max-width: 900px) {
    .fale {
        width: 80%;
        margin: auto;
    }
}


/*Rodapé Responsivo*/

@media screen and (max-width: 600px) {
    .fale {
        width: 90%;
        margin: auto;
    }

    section#contato textarea {
        margin-left: 5px;
    }

    section#contato input {
        margin-left: -10px;
    }

    .ladoss {
        width: 100%;
    }

    div#atendimento-whatsapp img {
        width: 200px;
        height: 100px;
        right: 20px;
    }

    .botao_enviar {
        margin-left: 5px;
    }
}

@media screen and (max-width: 400px) {
    .fale {
        flex-direction: column;
    }

    section#contato textarea {
        margin-left: 0;
    }

    section#contato input {
        margin-left: 0;
    }

    .botao_enviar {
        margin-left: 0;
    }
}


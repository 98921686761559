header {
    z-index: 100;
    width: 100%;
    height: 120px;
    /* background-color: #388E3C; */
    background-color: #144000;

}

.logo {
    text-decoration: none;
    color: white;
    padding-left: 5px;
}

img.img_logo {
    height: 150px;
    width: 150px;
    margin: auto;
    margin-left: 20px;
    padding-left: 25px;
}


#logo_2 {
    margin-left: -45px;
    z-index: 100;
}

.logo_menu_mobile {
    position: absolute;
    top: 10px;
    left: -10px;
}

.buscar {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-left: 240px;
}

.buscar input {
    width: 290px;
    padding: 10px;
    margin: 50px;
    display: grid;
    justify-content: center;
    align-items: center;
    border: 3px solid #7b8122;
    border-radius: 20px;
    outline: none;
    font-size: 0.9rem;
    z-index: 100;
}


.nav-link a::after {
    content: '';
    display: block;
    height: 2px;
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    transform-origin: right;
    transform: scale(0, 1);
    transition: transform ease-in-out 0.5s;
    z-index: 100;
}

/*  */

.columns#menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: -30px;
    z-index: 100;
    margin-top: -90px;
}


.content_nav li {
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    margin: 0 auto;
    z-index: 100;
}

/*
Ajustando a posição do menu quando o tamanho da tela for reduzido. 
E a margem do alinhamento do menu Burger.
*/

 @media (max-width:1020px) {

    .content_nav li { 
        margin-top: 35px;
    }
}


@media (max-width:800px) {

    .content_nav li { 
        margin-top: 0px;
    }
}



.content_nav li a {
    text-decoration: none;
    color: white;
    position: relative;
    font-size: 1.2rem;
    z-index: 10;
}

.content_nav li a:hover {
    text-decoration: none;
    color: #b46739;
    position: relative;
    z-index: 100;
}

.content_nav li a::after {
    content: '';
    display: block;
    height: 2px;
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    transform-origin: right;
    transform: scale(0, 1);
    transition: transform ease-in-out 0.5s;
    z-index: 100;
}

.content_nav li a:hover::after {
    transform: scale(1, 1);
    color: #144000;
}

.sub_menu-1 {
    display: none;
    z-index: 100;
}

ul.content_nav li:hover .sub_menu-1 {
    display: block;
    position: absolute;
    background-color: #144000;
    margin-top: 0;
    margin-left: -50px;
    padding: 5px;
    z-index: 100;
}


ul.content_nav li:hover .sub_menu-1 ul {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

ul.content_nav li:hover .sub_menu-1 ul li {
    background-color: #585e0e;
    padding: 10px;
    margin: 1px;
    width: 150px;
    text-align: center;
    transition: 0.7s ease-in;
    z-index: 100;
}

ul.content_nav li:hover .sub_menu-1 ul li:hover {
    background-color: #585e0e;
    padding: 10px;
    z-index: 100;
}

/**/

.mobile-menu-icon {
    display: none;
}

.mobile-menu-icon img {
    display: flex;
    justify-content: space-between;
    padding: -1rem;
    margin-left: 475px;
}

.nosso-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10px 0px;
}


.menu-mobile {
    background: #7b8122;
    z-index: 102;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 600px;
    display: none;
}

.menu-mobile .content_nav {
    display: none;
    width: 100%;
}

.menu-mobile .content_nav {  
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 1/4;
    text-align: center;
    max-height: 0;
    overflow: hidden;
    transition: all ease-in-out 0.6s;
}

.menu-mobile .content_nav {
    max-height: 500px;
}

.menu-mobile ul {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.menu-mobile .content_nav {
    display: flex;
    padding-top: 5rem;
    transition: 2s ease-in;
}

.open {
    display: block;
}

/*Formatação da Parte Responsiva do Menu*/

@media screen and (max-width: 900px) {
    #nosso-menu {
        width: 100%;
        /* background: #144000; */
        background: #4CAF50;
    }

    .buscar input {
        margin-right: 30px;
    }
}

@media screen and (max-width: 800px) {
    header {
        height: 100px;
    }

    #nosso-menu {
        width: 100%;
        background: #4CAF50;
        /* background: #144000; */
    }
    .avatar_do_usuario{
        left: 20px;
        transform: translateY(-20px);
    }
}

@media screen and (max-width: 800px) {

    .buscar input {
        display: none;
    }

    .logo {
        text-decoration: none;
        color: white;
        padding-left: 0;
    }

    img.img_logo {
        height: 90px;
        width: 80px;
        margin-top: -10px;
    }
}


@media (max-width:400px) {

    #logo_2{
        margin-top: -10px;
        margin-left: -30px;
        width: 100px;
    }
    
    .logo {
        margin-top: 0px;
        width: 500px;
        height: 50%;
    }
}

@media screen and (max-width: 750px) {
    header {
        height: 50px;
    }
}

@media screen and (max-width: 800px) {

    header {
        z-index: 100;
        width: 100%;
        height: 105px;
        background-color: #144000;

    }

    .label {
        display: grid;
        cursor: pointer;
        grid-column: 3;
        padding-right: 10px;
    }

    .content_nav li {
        padding: 15px 0px;
    }

    ul.content_nav li:hover .sub_menu-1 {
        display: block;
        position: relative;
        background-color: #144000;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }

    ul.content_nav li:hover .sub_menu-1 ul {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin: 0 auto;
    }

    ul.content_nav li:hover .sub_menu-1 ul li {
        background-color: #585e0e;
        padding: 10px;
        width: 150px;
        text-align: center;
        transition: 0.7s ease-in;
    }

    ul.content_nav li:hover .sub_menu-1 ul li:hover {
        background-color: #585e0e;
        padding: 10px;
    }
}


@media screen and (max-width:800px) {
    .nosso-menu {
        padding: 1.5rem 4rem;
    }

    .estilo_botao_pesquisa_corpo {
        display: none;
    }

    .content_nav {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }

    .mobile-menu-icon button {
        background: transparent;
        border: none;

        cursor: pointer;
    }

}

@media (max-width: 600px) {
    .mobile-menu-icon {
        margin-right: 20px;
    }


}

@media (max-width: 393px) {
    .nav-link {
        display: none;
    }
}


/*IPHONE*/

@media screen and (max-width: 667px) {
    .label {
        display: grid;
        cursor: pointer;
        grid-column: 3;
        padding-right: 10px;
    }

    .content_nav li {
        padding: 15px 0px;
    }

    ul.content_nav li:hover .sub_menu-1 {
        display: block;
        position: relative;
        background-color: #144000;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }

    ul.content_nav li:hover .sub_menu-1 ul {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin: 0 auto;
    }

    ul.content_nav li:hover .sub_menu-1 ul li {
        background-color: #585e0e;
        padding: 10px;
        width: 150px;
        text-align: center;
        transition: 0.7s ease-in;
    }

    ul.content_nav li:hover .sub_menu-1 ul li:hover {
        background-color: #585e0e;
        padding: 10px;
    }
}

@media screen and (max-width:667px) {
    .nosso-menu {
        padding: 1.5rem 4rem;
    }

    .content_nav {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }

    .mobile-menu-icon button {
        background: transparent;
        border: none;

        cursor: pointer;
    }

}

@media (max-width: 600px) {
    .mobile-menu-icon {
        margin-right: 10px;
    }
}

@media (max-width: 393px) {
    .nav-link {
        display: none;
    }
}
 
.novo_menu_novo {
    position: absolute; 
    top: 0;
    right: 0;
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.novo_menu_novo > div {
    background-color: hsl(0, 0%, 100%);
    width: 90%;
    height: 3px;
    border-radius: 2px;
    margin-right: -5px;
}


@media (max-width:800px) {
    .logo{
        display: none;
    }
}

@media (max-width:801px) {
    .logo-mobile{
        display: flex;
    }

    .logo-mobile img{
        width: 15%;
        height: 15%;
        display: flex;
        margin-left: 30px;
        padding: 5px;
    }
    
    .logo-mobile img:first-child{
        margin-right: 20px;
    }
} 

.avatar_do_usuario{
    position: absolute;
    top: 40px;
    right: 50px;
    width: 60px;
}
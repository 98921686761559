.leilaoItemBotao {
    margin-left: 1150px;
    padding: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;

}

.titleItem {
    font-size: 1.1rem;
    text-align: center;
}

/*  */
/* .btnInscreverFooter {
    display: flex;
    margin-left: 900px;
} */

.btn-Inscrever {
    background-color: #B46739;
    padding: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.1rem;
    width: 100px;
}

.btn-Jainscrever {
    background-color: #424616;
    padding: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    width: 100px;
    font-size: 1.1rem;
}

/*  */
.btn-titleItem {
    font-size: 1.1rem;
    text-align: center;
}

/**/
.btn-InscreverSair {
    background-color: #B46739;
    padding: 6px;
    border-radius: 6px;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
}

#bg_img_Leilao {
    width: 100%;
    height: 260px;
    background-image: url('../components/imgs/leilao-online.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#bg_img_Leilao::before {
    content: '';
    position: absolute;
    top: 10;
    left: 50;
    background: #0000007d;
    width: 98%;
    height: 260px;
    border-radius: 10px;
}

#bg_img_Leilao p {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: -121px;
    max-width: 1000px;
}

@media(max-width:785px) {
    #bg_img_Leilao p {
        margin-top: 0px;
    }

    #bg_img_Leilao::before {
        top: 10;
        left: 50;
        width: 93%;
    }

}

#bg_img_Leilao h1 {
    font-size: 2rem;
}

h1.txt-leilao-inscricao {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.produtos_da_compra_cart_filho {
    width: 80%;
    min-height: 600px;
    height: 80vh;
    background-color: #9ba236;
    border-radius: 20px;
    padding: 5px 10px;
    opacity: 0;
    transition: 1s;
}

/*Nova Formatacao*/

div.dizeres-posicao {
    width: 80%;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    margin-top: -60px;
}


div.dizeres-posicao p {
    text-align: justify;
    font-size: 1rem;
    margin-bottom: 10px;
    max-width: 90%;
}


@media (max-width:500px) {
    div.dizeres-posicao p {
        /* text-align: left; */
        font-size: 1rem;
        margin-bottom: 10px;
        max-width: 90%;
    }
}

.btnInscreverFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 60%;
    cursor: pointer;
}


a.btn-inscrever {
    background: #B46739;
    border-radius: 7px;
    padding: 7px;
    margin: 10px;
    text-align: center;
    width: 30%;
    color: white;
    font-size: 1rem;
    transition: 0.6s;
}

a.btn-Inscrever:hover {
    background: #d47944;
}

a.btn-sair {
    background: #B46739;
    border-radius: 7px;
    padding: 7px;
    text-align: center;
    width: 60px;
    color: white;
    font-size: 1rem;
}


p#clicar {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;
}


/*formatacao do carregamento do comprovativo de pagamento do leilao online*/

.tentativa {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'btn-carregar btn-enviar-comprovativo';
    /* justify-content: center;
    align-items: center;
    margin: 0 auto; */
}

.btn-carregar {
    grid-area: btn-carregar;
    font-size: 1rem;
    background: #B46739;
    border-radius: 7px;
    padding: 7px;
    outline: none;
    border: none;
}

.none {
    display: none;
}

.btn-enviar-comprovativo {
    grid-area: btn-enviar-comprovativo;
    font-size: 1rem;
    border-radius: 7px;
    padding: 7px;
    outline: none;
    border: none;
}

.enviar_pagamentos {
    font-size: 1rem;
    background: #424616;
    border-radius: 7px;
    padding: 8px;
    outline: none;
    border: none;
    color: white;
    /*  */
    /* width: 100%; */
}

.btnInscreverFooter input[type="file"] {
    display: none;
}

/**/

@media(max-width:600px) {

    .tentativa {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 'btn-carregar''btn-enviar-comprovativo';
        width: 100%;
    }

    .btn-carregar {
        font-size: 0.7rem;
        width: 100%;
    }

    .enviar_pagamentos {
        font-size: 0.7rem;
        width: 100%;
    }

    div.dizeres-posicao p {
        /* text-align: left; */
        font-size: 0.7rem;
        margin: 0;
        margin-bottom: 5px;
        max-width: 100%;
    }

}

@media (max-width:393px) {
    .enviar_pagamentos {
        font-size: 0.7rem;
        /*  */
        width: 100%;
    }

}

/**/




/* CC TIRANDO DO CARRINHO */
.venda_img {
    z-index: 2;
}

.venda_mi {
    padding: 20px 40px;
    /* transform: translateY(-160px); */
}

/* Carrinho */
.ver_compras_cart_1 {
    cursor: pointer;
    position: fixed;
    border: 2px solid #fff;
    top: 100px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-color: #686d25;
    border-radius: 50px;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ver_compras_cart_1 img {
    width: 35px;
    filter: invert(1);
    transform: translate(-1px, 2px);
}

.produtos_da_compra_cart_1 {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 600px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.671);
    z-index: 101;
}

.produtos_da_compra_cart_filho_1 {
    width: 80%;
    min-height: 600px;
    height: 80vh;
    background-color: #9ba236;
    border-radius: 20px;
    padding: 5px 10px;
    opacity: 0;
    transition: 1s;

}



.produtos_da_compra_cart_filho_animation_1 {
    opacity: 1;
}

.produtos_da_compra_cart_filho_1 h1 {
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    margin: 20px;
}

.active_cart_1 {
    display: flex;
}








/* --------------media query-------------------*/
@media(max-width: 992px) {
    .pagamento_final_compra {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        width: 100%;
    }

    .paga_final_1 {
        width: 675px;
        margin: 0 auto;
    }
}

@media (max-width: 900px) {
    .card_cart_pai_reapeat {
        grid-template-columns: 1fr 1fr;
    }

    .preco_do_botao {
        width: 200px;
    }

    .img_cart_img {
        height: 180px;
    }

    section.fundo_entrar h1 {
        font-size: 18px;
    }

    section.fundo_entrar p {
        font-size: 12px;
        max-width: 50%;
    }
}

@media (max-width: 820px) {
    .leilaoItemBotao {
        margin-left: 600px;
    }
}

@media (max-width: 800px) {
    #borda_img_p {
        top: 90px;
        height: 210px;
    }

    section.fundo_entrar {
        height: 200px;
    }

    section.fundo_entrar h1 {
        font-size: 20px;
    }

    section.fundo_entrar p {
        font-size: 13px;
    }

    section.fundo_entrar::before {
        height: 200px;
    }

    .preco_do_botao {
        width: 190px;
        transform: translate(20px, -50px);
    }
}

@media (max-width: 768px) {
    .preco_do_botao {
        width: 180px;
        transform: translate(20px, -50px);
    }

    .img_cart_img {
        height: 150px;
    }

    #borda_img_p {
        height: 170px;
    }

    section.fundo_entrar {
        height: 210px;
    }

    section.fundo_entrar::before {
        height: 210px;
    }

    section.fundo_entrar p {
        font-size: .9rem;
        max-width: 70%;
        text-align: center;
        margin: auto;
    }

}

/* media querie para modal */
@media (max-width: 740px) {
    div.dizeres-posicao {
        width: 100%;
        margin-top: -20px;
    }

    a.btn-Inscrever {
        margin-bottom: 10px;
        width: 60%;
    }
}


@media (max-width: 700px) {
    .card_cart_pai_reapeat {
        width: 100%;
        grid-gap: 15px;
    }

    .preco_do_botao {
        width: 250px;
        transform: translate(30px, -50px);

    }
}

@media (max-width: 650px) {
    .preco_do_botao {
        width: 250px;
        transform: translate(20px, -50px);
    }

    section.fundo_entrar h1 {
        font-size: 16px;
    }

    section.fundo_entrar p {
        font-size: 12px;
    }
}

@media(max-width: 600px) {
    .produtos_da_compra_cart_filho {
        width: 85%;
    }

    .preco_do_botao {
        width: 200px;
    }

    .preco_do_botao {
        width: 220px;
        transform: translate(30px, -50px);

    }
}

@media(max-width: 580px) {
    .preco_do_botao {
        width: 220px;
        transform: translate(20px, -50px);
    }
}

@media (max-width: 550px) {
    .titulo_login {
        font-size: 20px;
    }

    section.fundo_entrar h1 {
        transform: translateY(20px);
        font-size: 1.2rem;
    }

    #borda_img_p {
        height: 150px;
    }

    section.fundo_entrar {
        height: 150px;
    }

    section.fundo_entrar::before {
        height: 150px;
    }

    .preco_do_botao {
        width: 200px;
    }
}

@media (max-width: 500px) {
    .preco_do_botao {
        width: 180px;
        transform: translate(10px, -50px);
    }
}

@media (max-width: 475px) {
    .titulo_login {
        font-size: 18px;
    }

    .preco_do_botao {
        width: 180px;
    }

    .card_cart_pai_reapeat {
        width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }

    .img_cart_img {
        height: 200px;
    }

}

@media (max-width: 450px) {
    .preco_do_botao {
        width: 300px;
    }

    section.fundo_entrar h1 {
        font-size: 13px;
    }

    section.fundo_entrar p {
        font-size: 10px;
    }

    #borda_img_p {
        height: 100px;
    }

    section.fundo_entrar {
        height: 100px;
    }

    section.fundo_entrar::before {
        height: 100px;
    }
}

@media (max-width: 400px) {
    .img_cart_img {
        height: 180px;
    }


    .card_cart_pai_reapeat {
        width: 95%;
    }

    .titulo_login {
        font-size: 18px;
    }
}

@media (max-width: 375px) {
    .preco_do_botao {
        width: 250px;
    }
}

@media (max-width: 350px) {
    .preco_do_botao {
        width: 250px;
    }
}









@media(max-width: 694px) {
    .banco_paga {
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        width: 100%;

    }

    @media(max-width: 695px) {

        .paga_final_1 {
            width: 500px;
            margin: 0 auto;
        }
    }

    @media(max-width: 517px) {

        .paga_final_1 {
            width: 300px;
            margin: 0 auto;
        }
    }


    button.enviar_button_pagamentos {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 1rem;
    }

    button.btn_banco {
        width: 110px;
    }
}

@media(max-width: 500px) {
    /*Formatando o espaçamento entre os botoes do carrinho*/

    .baixo_cart_baixo button,
    .enviar_button_pagamentos {
        margin: 5px;
        font-size: 0.6rem;
    }
}

@media(max-width: 310px) {
    /*Formatando o espaçamento entre os botoes do carrinho*/

    .baixo_cart_baixo {
        margin: 5px;
        font-size: 0.6rem;
        display: grid;
        justify-content: center;
        align-items: center;
    }
}


/*Responsividade de Finalizar compra*/
/*Solicitar Transporte*/

@media(max-width: 901px) {
    .tabela_final {
        margin: 0 auto;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .info-finalizar-compra {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 700px;
    }

    .localizacao_destino select {
        margin: 5px;
    }

    /*Informacoes da entrega (FIM)*/

}

@media(max-width: 694px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 580px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
    }

    .localizacao_destino select {
        padding-top: 6px;
        margin: 5px;
        width: 490px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        flex-direction: column;
    }

    .form_destino_botao {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    /* button.btn_laranja{
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    /*Informacoes da entrega (FIM)*/
}



@media(max-width: 574px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
    }

    .localizacao_destino select {
        padding-top: 6px;
        margin: 5px;
        width: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    /*Informacoes da entrega (FIM)*/
}



@media(max-width: 505px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    #info-entrega-destino {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .localizacao_destino {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
    }

    .localizacao_destino select {
        padding-top: 6px;
        margin: 7px;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    /*Informacoes da entrega (FIM)*/
}


@media(max-width: 502px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .localizacao_destino select {
        margin: 2px;
    }

    /*Tipografia do Texto*/

    #txt-inicio-servico-de-entrega {
        font-size: 1.2rem;
    }

    h1#txt-info-entrega {
        font-size: 0.7rem;
    }


    /*Informacoes da entrega (FIM)*/
}



@media(max-width: 359px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
    }

    .localizacao_destino select {
        padding-top: 6px;
        margin: 7px;
        width: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .form_destino_botao {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100px;
    }

    button.btn_laranja {
        width: 388px;
        font-size: 0.8rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*Informacoes da entrega (FIM)*/
}



@media(max-width: 502px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 580px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .localizacao_destino select {
        margin: 2px;
    }



    /*Informacoes da entrega (FIM)*/
}

/*Informe o local*/

div#txt-local-entrega {
    font-size: 25px;
    color: #fff;
    text-align: center;
    margin-top: 15px;
}

@media (max-width: 500px) {
    div#txt-local-entrega h1 {
        font-size: 16px;
    }
}


@media(max-width: 604px) {
    .tabela_final_t table {
        width: 50%;
        margin: 0 auto;
    }
}

@media(max-width: 524px) {
    .tabela_final_t {
        font-size: 0.9rem;
    }

    .img_cart_img_final {
        width: 55px;
        height: 45px;
    }
}


@media(max-width: 445px) {
    .tabela_final_t table {
        width: 30px;
        font-size: 0.8rem;
    }

    .img_cart_img_final {
        width: 50px;
        height: 40px;
    }
}


@media(max-width: 428px) {
    .tabela_final_t table {
        width: 25px;
        font-size: 0.7rem;
    }

    .img_cart_img_final {
        width: 45px;
        height: 35px;
    }
}


@media(max-width: 409px) {
    .tabela_final_t table {
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        min-width: 90%;
        border: none;
    }

    .g_total {
        min-width: 100%;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 10px 10px;
        font: 0.7rem;
    }

    .g_total-1 {
        min-width: 100%;
        /* height: 10px; */
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 10px 10px;
        font: 0.7rem;
    }
}

@media(max-width: 280px) {
    .tabela_final_t table {
        min-width: 100%;
    }

    .img_cart_img_final {
        width: 30px;
        height: 25px;
    }
}

h1#txt-selecionar-transporte {
    font-size: 1.2rem;
}


/*Transporte*/
/* ----------------- Input ------------------------*/

@media(max-width: 451px) {
    h1#txt-selecionar-transporte {
        font-size: 0.8rem;
    }

}


@media(max-width: 451px) {
    .transporte_and_input {
        display: flex;
        flex-direction: column;
        /* display: none; */
    }

    .pai_pesquisa {
        width: 100%;
        width: 100%;
        border-radius: 30px;
        border: 2px solid #fff;
        padding: 5px 20px;
        background: #ffffff7a;
        color: #000;
        outline: none;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .pai_pesquisa input {
        width: 100%;
        font-size: 18px;
        border: none;
        background-color: transparent;
        outline: none;
        margin-bottom: 10px;

    }


}

@media(max-width: 443px) {
    .transporte_and_input {
        display: flex;
        flex-direction: column;
        /* display: none; */
    }

    #doisFinal-1 {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*Barra de Pesquisa de transportes disponiveis*/

    .pai_pesquisa {
        width: 270px;
        border-radius: 30px;
        border: 2px solid #fff;
        padding: 5px 20px;
        background: #ffffff7a;
        color: #000;
        outline: none;
        margin-bottom: 15px;

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .pai_pesquisa input {
        width: 200px;
        font-size: 18px;
        border: none;
        background-color: transparent;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

    }
}

/*formatando o botao para a escolha do transporte*/

h1#selecione-transporte {
    color: white;
    font-size: 25px;
    color: fff;
    text-align: center;
    margin-top: 15px;
}

@media(max-width: 415px) {
    .btn_escolher, .btn_laranja {
        padding: 4px;
        font-size: 12px;
    }

    /* .barra-laranja-transporte{
        background: red;
    } */

    h1#selecione-transporte {
        font-size: 17px;
        color: fff;
        text-align: center;
        margin-top: 15px;
    }
}


/*Formatando o posicionamento dos cards de finalizar compra (solicitar o transporte)*/

@media(max-width: 401px) {
    .tabela_final {
        margin: 0 auto;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .info-finalizar-compra {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
    }
}


.valor-preco-inicial-leilao {
    margin-top: 40px;
    background-color: #9ba236;
    text-align: center;
    border-radius: 20px;
}

@media(max-width: 1020px) {
    .valor-preco-inicial-leilao {
        margin: 0px;
        background-color: #9ba236;
        text-align: center;
        border-radius: 20px;
    }
}


/*Formatando a entrega final *Confirmacao do transporte*(*/

@media (max-width: 900px) {
    .entrega_final {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .entrega_final>div {
        background-color: #bdc466;
        border-radius: 20px;
        width: 100%;
        padding: 0;
        padding-bottom: 10px;
    }

}

@media (max-width: 530px) {
    .entrega_final {
        font-size: 1rem;
    }

}

/*Responvidade do servico de entregas (ao mostrar o total de transporte para o cliente)*/

@media(max-width: 780px) {
    div#mostrar-total-d-transporte {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}


@media(max-width: 691px) {
    div#mostrar-total-d-transporte {
        width: 100px;
    }

    div#mostrar-total-d-transporte table {
        width: 100px;
        font-size: 0.8rem;
    }
}

@media(max-width: 834px) {

    div#mostrar-total-d-transporte table {
        width: 100px;
        font-size: 0.8rem;
    }
}

@media(max-width: 669px) {
    div#mostrar-total-d-transporte .verde_total_entrega table {
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-align: left;
    }
}


@media(max-width:522px) {
    div#mostrar-total-d-transporte {
        width: 95%;
        margin: 5px;
        overflow-x: scroll;
        transform: translateX(5px);
        border-radius: 10px;
        font-size: 12px;
    }

    /* table.teste-posicao{
        transform: translateX(100px);
    } */

}


/*fim da formatacao de mostrar o total de entrega*/


/*Novo*/

/* table#dados{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; } */

/*Fim*/


@media(max-width: 522px) {
    div.verde_total_entrega {
        display: block;
    }
}

div.verde_total_entrega button {
    margin: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width: 669px) {
    div.verde_total_entrega button {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 0.8rem;
        margin-bottom: 7px;
    }
}


@media(max-width: 520px) {

    /**/
    #ScrollDiv {
        /* 
        width: 500px;
        overflow-x: auto;
        height: 200px;
       margin-left: auto;
        margin-right: auto;
        display: none; */
    }

    table#dados {
        width: 100%;
        position: relative !important;
    }

    /* div.rolamento{
        display: grid;
        height: auto;
    }

    div#eriv{
        background: red;
        width: 150px;
    } */
}

/* div.rolamento{
    overflow-x: auto;
}   */

/*Toda responsividade que tem haver com o processo de compras.*/




@media(max-width:400px) {
    /* .testando-posicao
    {
        margin-left: 17px;
        width: 100%;
    } */

    table.tabela-transporte {
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}




/* #teste-de-trans{
    background: red;
    width: 400px;
} */

@media(max-width:414px) {
    /*  */
    .btnInscreverFooter {
        text-align: center;
        margin: 0 auto;
        width: 100%;
    }
}

@media(max-width:410px) {
    .ocultar-img {
        display: none;
    }

}


@media (max-width:740px) {
    .leilaoItemBotao {
        margin-left: 200px;
    }

}

/* @media (max-width:820px) {
    .produtos_da_compra_cart_filho_1 {
        height: 55vh;
    }
    div.dizeres-posicao {
        width: 80%;
        display: grid;
        margin-top: -60px;
    }
    div.dizeres-posicao p {
        font-size: 1.1rem;
        margin: 0;
        margin-bottom: 5px;
        max-width: 100%;
    }

} */

@media (max-width:360px) {
    .produtos_da_compra_cart_filho_1 {
        height: 90vh;
    }
}

@media (max-width:375px) {
    div.dizeres-posicao {
        width: 100%;
        display: grid;
        margin-top: -60px;
    }

    /* div.dizeres-posicao p {
        font-size: 1rem;
    } */

    .produtos_da_compra_cart_filho_1 {
        height: 97vh;
    }

    /*  */
    .btnInscreverFooter {
        text-align: center;
        margin: 0 auto;
        width: 100%;
    }
}

/*  */
@media (max-width:280px) {
    div.dizeres-posicao p {
        font-size: 1rem;
    }

    .leilaoItemBotao {
        margin-left: 130px;
    }

    .titleItem {
        font-size: 0.6rem;
    }

}



section.fundo_entrarFoto {
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    max-width: 100%;
    margin-top: 5rem;
}
@import "../src/style/animais/styles.css";
@import "../src/style/cooperativas/style.css";
@import "../src/style/lance/lance.css";
@import "../src/style/leilao/styles.css";
@import "../src/style/menu/novo-menu.css";
@import "../src/style/sobre_animais/styles.css";
@import "../src/style/sobre_compras/styles.css";
@import "../src/style/sobre_cooperador/styles.css";
@import "../src/style/sobre_fazenda/styles.css";
@import "../src/style/vendas/styles.css";
@import "../src/style/verificacao/styles.css";
@import "../src/style/perfiluser/perfil.css";
@import "../src/style/nav.css";
@import "../src/style/login.css";
@import "../src/style/bulma.css";
@import "../src/style/responsive.css";
@import "../src/style/styles.css";
@import "../src/style/footer.css";
@import "../src/style/blog.css";
@import "../src/style/pagination.css";
@import "../src/style/termos/termos.css";

@import "../src/style/nova-noticia.css";
@import "../src/style/visitante.css";

@import "../src/style/modalParticipante.css";
@import "../src/style/perfiluser/modalPerfil.css";
/*  */
@import "../src/style/loading.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    overflow-x: hidden;
    
}



/* .App {
    font-family: sans-serif;
    text-align: center;
} */
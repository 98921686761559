body {
    background: #9ba236;
    margin: 0;
    padding: 0;
}

section #inicio {
    background: url("../../components/imgs/Cultura Coop_Cuando Cubango II.png");
    /* background: url("../imgs/Cultura Coop_Cuando Cubango II.png"); */
    background-position: center;
    background-size: cover;
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
}

section #inicio::before {
    content: '';
    position: absolute;
    top: 4;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #00000094;
    z-index: 0;
}

section #inicio {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section .welcome {
    position: absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section .welcome h1 {
    color: white;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section .welcome p {
    color: white;
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
    top: 0;
}

.procurar {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 17rem;
}

.column a.button {
    width: 8rem;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 #9ba236;
}

section.fazendas {
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.fazendas .columns img {
    border-radius: 10px;
    max-width: 100%;
    background-size: contain;
    height: 30vh;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

section.fazendas .mi {
    background: #424616;
    padding: 10px;
    margin: 40px;
    border-radius: 15px;
}

section.inf-contato {
    background: white;
}

.column h2,
p {
    color: white;
}

.column a.button:hover {
    box-shadow: inset 250px 0 0 #b46739;
    color: white;
    transition: 0.5s ease-in;
}

.columns footer {
    display: inline-block;
    justify-content: center;
    align-items: center;
    background: coral;
}

section.inf-contato img#img_logo {
    width: 15%;
    height: 13vh;
    background: #9ba236;
    border-radius: 50%;
}

section.inf-contato h1 {
    text-align: center;
    margin: 0 auto;
    font-family: Arial, Helvetica, sans-serif;
}

section.inf-contato #contato {
    display: grid;
    justify-content: center;
    align-items: center;
}

footer .redes_sociais {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

footer .redes_sociais img {
    height: 5vh;
    max-width: 100%;
    margin: 5px;
    transition: 0.3s;
    cursor: pointer;
}

footer .redes_sociais img:hover {
    padding-top: 15px;
}

footer .texto {
    width: 600px;
    margin: 0 auto;
}

footer .texto p {
    color: black;
}

img.coperativas-fotos {
    border: #424616 3px solid;
}

/* .img_do_cooperador {
    border-radius: 10px;
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 230px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
} */

.img_do_cooperado {
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    height: 280px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    /* display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
}



.img_do_produto {
    /* width: 100%;
    height: 100px
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    border-radius: 10px;
     max-width: 100%;
    /* max-height: 100%; */
    height:190px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    margin: 10px;

}






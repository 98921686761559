section.descricao {
    background: #686d25;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}


@media(max-width:500px){
    section.descricao {
        margin-bottom: 20px;
        margin-top: 80px;
    }
}


.conteudo {
    background: #686d25;
}

.info_animal {
    max-width: 1000px;
}

.descricao h1 {
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    font-size: 2rem;
}

.descricao p {
    text-align: justify;
    color: white;
    padding: 5px;
}

.cima_preco_valor {
    display: flex;
    justify-content: space-between;
}

.valor-preco-venda {
    background-color: #a7ac69;
    padding: 10px;
    border-radius: 10px;
    margin-top: -45px;
    border: 3px solid #686d25;
}
.doisBotVerLote{
    display: flex;
    justify-content: center;
    align-items: center;
}
/*Botao sobre_compra*/
.cart-preco-venda button{
    background-color: #a7ac69;
    padding: 10px;
    border-radius: 10px;
    margin: auto;
    margin-top: 45px;
    border: 3px solid #686d25;
    color: #fff;
    font-size: 17px;
}

.cart-preco-venda a{
    /* background-color: #a7ac69; */
    padding: 10px;
    border-radius: 10px;
    margin: auto;
    margin-top: 45px;
    /* border: 3px solid #686d25; */
    color: #fff;
    font-size: 17px;
}




/*botao */

@media(max-width:500px){
     .descricao-art-venda {
        margin-top: 300px;
    }
}


@media(max-width:340px){
    .cart-preco-venda a{
        margin-top: -12px;
    }
}

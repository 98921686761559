:root {
  --color-text: #1f8fc3;
  --color-bg-dark: #11101D;
  --color-dark: #1D2231;
  --color-blue: #0d6efd;
  --color-orange: #fd7e14;
  --color-yellow: #ffc107;
  --color-green: #198754;
  --color-white: #fff;
  --color-gray: #8390A2;
  --color-gray-dark: #343a40;
  --btn-primary: #0d6efd;  
  --btn-secondary: #6c757d;
  --btn-success: #198754;
  --btn-info: #0dcaf0;
  --btn-warning: #ffc107;
  --btn-danger: #585e0e;
  --btn-light: #f8f9fa;
  --btn-dark: #212529;
  --shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

/*************************************************
  #                MODAL LOGIN
  *************************************************/
/* .modal {
  display: none;
}

.modal:target {
  display: flex;
}


.modal:target {
  justify-content: space-around;
  align-items: center;
} */


.modal__close {
  color: initial;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.5rem;
  display: inline-block;
}

/****************************************************************
#                     FORMULARIOS DESPESAS E MODAL
*****************************************************************/


.modalDesp {
  display: none;
}

.modalDesp {
  text-align: left;
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  z-index: 100;
}

.modalDesp:target {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/***Conteudo do Modal***/
.modal__content {
  max-width: 100vw;
  width: 100%;
}

/***Link para fechar***/
.modal__overlayDesp {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: default;
}

/***Conteudo do Modal***/
.modal__contentDesp {
  max-width: 40vw;
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 2rem;
}
.modal__contentDesp2 {
  max-width: 70vw;
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 2rem;
}

.larguradaModal{
  width: 40vw;
}

/**/
.inputModalDesp {
  width: 100%;
  padding: 10px 0;
  outline: none;

}



#cadastroH3 {
  color: var(--color-dark);
  padding: 10px 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  padding-bottom: 30px;
}

#cadastroH3:after {
  content: ' ';
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 10px;
  background: -webkit-linear-gradient(left, #1f8fc3, #062642, #1f8fc3 100%);
  background: linear-gradient(left, #1f8fc3, #062642, #1f8fc3 100%);
}

.cadastroP {
  margin-bottom: 15px;
}

.cadastroP:first-child {
  margin: 0px;
}

.cadastroLabel {
  color: var(--color-dark);
  position: relative;
  font-weight: bold;
}


.preview img.image{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  height: 50%;
}


/* REMOVER IMAGEM */
.delete {
  background-color: var(--btn-danger);
  float: right;
}

.delete:hover {
  background-color: var(--btn-danger);
}

.btnSalvar {
  background-color: var(--btn-danger);
  color: #FFFF;
  border-radius: 6px;
  padding: 6px;
  border: none;
  outline: none;
  margin: 6px;
  cursor: pointer;
}
 

/****************************
      Medias Queries
****************************/

@media screen and (max-width: 760px) {

  /***Conteudo do Modal***/
  .modal__contentDesp {
    max-width: 100vw;
    /* width: 100%; */
  }

  /***Conteudo do Modal***/
  .modal__content {
    max-width: 100vw;
    width: 100%;
  }


}

/*******/
@media only screen and (max-width: 560px) {
  .cards {
    grid-template-columns: 100%;
  }

}
section.descricao {
    background: #686d25;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

h2.detalhes_animal{
    text-align: center;
}

.conteudo {
    background: #686d25;
}

.info_animal {
    max-width: 1000px;
}

.descricao h1 {
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    font-size: 2rem;
}

.descricao p {
    text-align: left;
    color: white;
    padding: 5px;
}

 
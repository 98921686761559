/* .pagination_section {
   display: flex;
   align-items: center;
   justify-content: center;
} */


.pagination_section {
    color: #444444;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination_section ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.pagination_section li {
    margin: 0 5px;
    transition: 0.3s;
}

.pagination_section li button {
    color: #111;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination_section li.active, .pagination_section li:hover {
    background: #1bbd36;
}

.pagination_section li.active button, pagination_section li:hover button {
    color: #fff;
}
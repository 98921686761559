.venda_img {
    z-index: 2;
}

.venda_mi {
    padding: 20px 40px;
    /* transform: translateY(-160px); */
}

#img_vendass {
    position: relative;
    width: 100%;
    height: 150px;
    transform: translateY(-150px);
    background: url("../../components/imgs/TRIANGULO\ ACTIVIDADES-09.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 3;

} 
 
#img_vendass2 {
    position: relative;
    width: 100%;
    height: 180px;
    transform: translateY(-130px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 3;
}

.dois_btn_cart {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.btn_cart_btn {
    width: 40%;
    padding: 5px;
    border-radius: 20px;
    transition: ease-in-out .3s;
}

.dois_btn_cart a img {
    width: 30px;
    height: 30px;
    filter: invert(1);
}

.dois_btn_cart a:nth-child(1) {
    background-color: #9ba236;
}

.dois_btn_cart a:nth-child(2) {
    background-color: #B46739;
}

.dois_btn_cart a:nth-child(1):hover {
    background-color: #B46739;
}

.dois_btn_cart a:nth-child(2):hover {
    background-color: #9ba236;
}

/* Carrinho */
.ver_compras_cart {
    cursor: pointer;
    position: fixed;
    border: 2px solid #fff;
    top: 100px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-color: #686d25;
    border-radius: 50px;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ver_compras_cart img {
    width: 35px;
    filter: invert(1);
    transform: translate(-1px, 2px);
}

.produtos_da_compra_cart {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 600px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.671);
    z-index: 101;
}

.produtos_da_compra_cart_filho {
    width: 80%;
    min-height: 600px;
    height: 80vh;
    background-color: #9ba236;
    border-radius: 20px;
    padding: 5px 10px;
    opacity: 0;
    transition: 1s;

}

.remover_do_carrinho {
    font-size: 1.2em;
    cursor: pointer;
    /* position: absolute; */
    /* top: -147px;
    left: 200px; */
    width: 30px;
    color: #fff;
    background-color: #B46739;
    padding: 1px 10px;
    border-radius: 20px;
    /* transform: translateX(261px); */
    transform: translateY(30px);
}

.produtos_da_compra_cart_filho_animation {
    opacity: 1;
}

.produtos_da_compra_cart_filho h1 {
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    margin: 20px;
}

.card_cart_pai_reapeat {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.flex_cima_carrinho {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_cima_carrinho span {
    color: #B46739;
    font-weight: bold;
}

.flex_nome_preco {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*
.card_cart_repeat {
    display: flex;
    background-color: #bdc466;
    border-radius: 15px;
    flex-direction: column;
}
*/
.img_cart_img {
    width: 100%;
    margin: auto;
    height: 150px;
    /*background-image: url('../imgs/nullvaca32.jpg');*/
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.img_cart_img_final {
    width: 100px;
    margin: auto;
    height: 70px;
    /*background-image: url('../imgs/nullvaca32.jpg');*/
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.legenda_cart_legenda {
    padding: 20px;
    background-color: #bdc466;
    border-radius: 0 0 20px 20px;
}

.legenda_cart_legenda h4 {
    color: #B46739;
    font-size: 1.2rem;
}

.legenda_cart_legenda span {
    color: #fff;
    font-weight: bold;
}

.baixo_cart_baixo {
    background-color: #bdc466;
    margin: 5px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 15px;
}

.baixo_cart_baixo_flex {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.button_fechar_carrinho {
    display: flex;
    align-items: flex-end;
}

.baixo_cart_baixo span {
    color: #B46739;
}

.baixo_cart_baixo h2 {
    color: #fff;
    font-size: 1.7rem;
    text-align: center;
}

.baixo_cart_baixo p {
    color: #fff;
    font-size: 1.1rem;
    margin: 5px 0;
}

.baixo_cart_baixo h3 {
    color: #fff;
    font-size: 1.3rem;

}

.baixo_cart_baixo button,
.enviar_button_pagamentos {
    color: #fff;
    font-size: 1.1rem;
    margin: 5px 0;
    background-color: #B46739;
    border: none;
    padding: 5px 20px;
    border-radius: 10px;
    cursor: pointer;
}

.active_cart {
    display: flex;    
}

/* Login */

section.fundo_entrar {
    /* background: url("../../components/imgs/Cultura Coop_Cuando Cubango II.png"); */
    background: url("../../components/imgs/Cultura Coop_Namibe.png");
    /* background: url("../imgs/Cultura Coop_Cuando Cubango II.png"); */
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    max-width: 100%;
    margin-top: 5rem;
}

section.fundo_entrar::before {
    content: '';
    position: absolute;
    top: 1;
    left: 0;
    height: 250px;
    width: 100%;
    background: #00000094;
}

section.fundo_entrar {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 4;
}

section.fundo_entrar h1 {
    font-size: 24px;
}

section.fundo_entrar p {
    font-size: 14px;
    max-width: 60%;
    text-align: center;
}

/*Borda da imagem*/

#borda_img_p {
    width: 100%;
    height: 260px;
    position: absolute;
    top: 175px;
    z-index: 2;
    left: 0px;
    background-image: url('../../components/imgs/borda_img.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.titulo_login {
    font-size: 30px;
}

.preco_don_botao {
    background: #9ba236;
    width: 240px;
    /* position: absolute; */
    /* transform: translate(20px, -50px); */
    /* z-index: 4; */
    padding: 10px 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    border: 1px solid #4e5219;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
}

.btn_flex_cart {
    display: flex;
    justify-content: space-around;
    min-width: 100px;
}

.qtd_do_cart {
    display: flex;
    margin: 10px 0;
}

.btn_btn_cart_qtd {
    background-color: #686d25;
    padding: 1px 8px;
    border-radius: 30px;
    cursor: pointer;
}

/* Swipper JS*/

.doisFinal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagamentos {
    background-color: red;
}

.contswiper {
    height: 380px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    margin-top: -50px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.sli_flex {
    display: flex;
    /* background-color: #bdc466; */
    border-radius: 15px;
    flex-direction: column;
    width: 290px;
}

.swiper-slide {

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet {
    text-align: center;
    font-size: 12px;
    color: #fff;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
}


/* --------------------------------- */
/* Final */
.grid_final {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 20px;
}

 

.entrega_final {
    width: 100%;
    margin-left: 30px;
}

.entrega_final>div {
    background-color: #bdc466;
    border-radius: 20px;
    width: 100%;
    padding: 0;
    padding-bottom: 10px;
}

.entrega_final h1,
.pagamento_final_compra h1 {
    font-size: 20px;
    color: #fff;
    border-radius: 10px 10px 0 0;
    background-color: #B46739;
    text-align: center;
}

/*Pagina Confirmacao*/
.pagamento_confirmacao h1 {
    font-size: 20px;
    color: #fff;
    border-radius: 10px 10px 0 0;
    background-color: #B46739;
    text-align: center;
}


.entrega_final p {
    font-size: 16px;
    color: #000;
}

.importante_compra {
    width: 650px;
}

/* ----------------- Input ------------------------*/

.transporte_and_input {
    display: flex;
    flex-direction: column;
    /* display: none; */
}

.pai_pesquisa {
    width: 100%;
    width: 100%;
    border-radius: 30px;
    border: 2px solid #fff;
    padding: 5px 20px;
    background: #ffffff7a;
    color: #000;
    outline: none;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.pai_pesquisa input {
    width: 100%;
    font-size: 18px;
    border: none;
    background-color: transparent;
    outline: none;

}

.img_pesquisa {
    background: url("../../components/imgs/icons/icon_pesquisa.png");
    /* background-image: url('../imgs/icons/icon_pesquisa.png'); */
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

/*-----------------------------------*/
.tabela_final {
    margin: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}

.tabela_final_t {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.tabela_final_t>div {
    width: 100%;
    background-color: #bdc466;
    border-radius: 20px;

}

/* @media (max-width:357px){
    #erivaldo-tr{
        background: red;
        width: 100%;
    }
} */



/* .total_trnasporte {
    margin-top: 20px;
} */

.tot_letra_transporte h2 {
    color: #000;
    font-weight: bold;
}

.tot_letra_transporte h2 span {
    color: #B46739;
    font-weight: bold;
}

.tot_letra_transporte {
    padding: 15px 20px;
}

.total_trnasporte button {
    padding: 5px 20px;
    background-color: #B46739;
    color: #fff;
    font-size: 17px;
    border-radius: 20px;
    border: 1px solid #fff;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
}

.tabela_final table {
    width: 100%;
}

.tabela_final table tr:nth-child(1) {
    background-color: #B46739;
}

.tabela_final table tr th {
    color: #fff;
}

.tabela_final table tr td,
.tabela_final table tr th {
    padding: 10px;
    text-align: center;
}

.paga_final,
.paga_final_1 {
    background-color: #bdc466; 
    margin: 20px;
    border-radius: 20px;
    padding-bottom: 10px;
}

.paga_final h1,
.paga_final_1 h1 {
    text-align: center;
    font-size: 20px;
    padding: 5px;
}

.pagamento_final_compra {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.pagamento_final_compra table tr td {
    padding: 2px;
}

.pagamento_final_compra table tr td:nth-child(1) {
    color: #B46739;
    font-weight: bold;
}

.pagamento_final_compra table tr td:nth-child(2) {
    color: #000;
}

.pagamento_final_compra table {
    margin: 10px;
}

.paga_final p {
    color: #000;
    text-align: center;
    font-size: 17px;
}


.banco_paga {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.banco_paga button {
    padding: 5px 20px;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    background-color: #fff;
    height: 40px;
    width: 150px;
    color: #fff;
    font-size: 15px;
    border-radius: 30px;
    border: 2px solid #fff;
    margin: 10px;
    cursor: pointer;
}

.banco_paga button:nth-child(1) {
    padding: 0;
    background-size: 100%;
    border: none;
}

.btn_angolano {
    
    background-image: url('../../components/imgs/icons/Angola\ AO-01.png');


}

.btn_paypal {
    background-image: url('../../components/imgs/icons/PayPal-Logo.png');
}

.btn_visa {
    background-image: url('../../components/imgs/icons/visa.jpg');
}

.btn_mastercade {
    background-image: url('../../components/imgs/icons/Mastercard-Simbolo.jpg');
}

.flex_nome_paga {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.img_pdf_enviar {
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    background-image: url('../../components/imgs/icons/pdfimg.png');
    width: 50px;
    height: 50px;
}

.bancoh2 {
    text-align: center;
    margin: 10px;
    color: #B46739;
    font-size: 20px;
}

.bancoh3 {
    margin-left: 10px;
    color: #000;
    font-size: 17px;
}

.flex_servico_entrega {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.g_total {
    background-color: #B46739;
    color: #fff;
    font-size: 18px;
}

.g_total-1   {
    background-color: #B46739;
    color: #fff;
    font-size: 18px;
}

.flex_servico_entrega button {
    padding: 5px 20px;
    border-radius: 10px;
    background-color: #B46739;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    border: 1px solid #fff;
    cursor: pointer;
}

.flex_servico_entrega button:nth-child(2) {
    background-color: #888;
    color: #fff;
}

.ver_loc_entrega {
    margin-top: 20px;
}

.localizacao_destino {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.localizacao_destino select {
    padding: 5px 20px;
    border-radius: 10px;
    background-color: #686d25;
    color: #fff;
    font-size: 16px;
    outline: none;
}

.info_entrega {
    background-color: #B46739;
    color: #fff;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    border-radius: 10px 10px 0 0;
}

.fim_letra_pagamentos {
    text-align: left;
    padding: 10px;
}

.fim_letra_pagamentos h2 {
    text-align: left;
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
}

.fim_letra_pagamentos p {
    text-align: left;
    color: #000;
}

.fim_letra_pagamentos input[type="file"] {
    display: none;
}

.fim_letra_pagamentos label {
    background-color: #B46739;
    padding: 10px 20px;
    font-size: 20px;
    color: #fff;
    margin: auto;
    cursor: pointer;
    transition: 2s;
    min-width: 300px;
    text-align: center;
}

.btn_escolher, .btn_laranja {
    padding: 5px 10px;
    background-color: #B46739;
    color: #fff;
    border-radius: 10px;
    border: 2px solid #fff;
    font-size: 17px;
    cursor: pointer;
}

.btn_laranja {
    margin-right: 20px;
}

.form_destino_botao {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.display_desaparecer {
    display: none;
}

.display_aparecer {
    display: block;
}

.final_transporte_card {
    background-color: #bdc466;
    color: #fff;
    margin: 30px 0;
    padding: 20px;
    border-radius: 10px;
}

.verde_total_entrega {
    background-color: #bdc466;
    border-radius: 10px;
}

/* ---------------------------------*/
@media (max-width: 1200px) {
    .preco_do_botao {
        width: 300px;
        transform: translate(20px,-50px);
    }
}

@media (max-width: 1140px) {
    .preco_do_botao {
        width: 300px;
        transform: translate(10px, -50px);
    }
}

@media (max-width: 1100px) {
    .preco_do_botao {
        width: 270px;
        transform: translate(20px, -50px);
    }
}

@media (max-width: 1030px) {
    .preco_do_botao {
        width: 240px;
        transform: translate(20px, -50px);
    }
}

@media (max-width: 930px) {
    .preco_don_botao {
        width: 200px;
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .card_cart_pai_reapeat {
        grid-template-columns: 1fr 1fr;
    }

    .preco_do_botao {
        width: 200px;
    }

    .img_cart_img {
        height: 180px;
    }

    section.fundo_entrar h1 {
        font-size: 18px;
    }

    section.fundo_entrar p {
        font-size: 12px;
        max-width: 50%;
    }
}

@media (max-width: 850px) {
    .preco_do_botao {
        width: 200px;
        transform: translate(20px, -50px);
    }
}

@media (max-width: 800px) {
    #borda_img_p {
        top: 90px;
        height: 210px;
    }

    section.fundo_entrar {
        height: 200px;
    }

    section.fundo_entrar h1 {
        font-size: 20px;
    }

    section.fundo_entrar p {
        font-size: 13px;
    }

    section.fundo_entrar::before {
        height: 200px;
    }

    .preco_do_botao {
        width: 190px;
        transform: translate(20px, -50px);
    }
}

@media (max-width: 768px) {
    .preco_do_botao {
        width: 180px;
        transform: translate(20px, -50px);
    }

    .img_cart_img {
        height: 150px;
    }

    #borda_img_p {
        height: 170px;
    }

    section.fundo_entrar {
        height: 210px;
    }

    section.fundo_entrar::before {
        height: 210px;
    }

    section.fundo_entrar p {
        font-size: .9rem;
        max-width: 70%;
        text-align: center;
        margin: auto;
    }

}
/* 
.vai_subir:nth-child(n + 5) {
    transform: translateY(-150px);
} */

@media (max-width: 750px) {
    .preco_do_botao {
        width: 280px;
        transform: translate(30px, -50px);
    }
}


@media (max-width: 700px) {
    .card_cart_pai_reapeat {
        width: 100%;
        grid-gap: 15px;
    }

    .preco_do_botao {
        width: 250px;
        transform: translate(30px, -50px);

    }
}

@media (max-width: 650px) {
    .preco_do_botao {
        width: 250px;
        transform: translate(20px, -50px);
    }

    section.fundo_entrar h1 {
        font-size: 16px;
    }

    section.fundo_entrar p {
        font-size: 12px;
    }
}

@media(max-width: 600px) {
    .produtos_da_compra_cart_filho {
        width: 85%;
    }

    .preco_do_botao {
        width: 200px;
    }

    .preco_do_botao {
        width: 220px;
        transform: translate(30px, -50px);

    }
}

@media(max-width: 580px) {
    .preco_do_botao {
        width: 220px;
        transform: translate(20px, -50px);
    }
}

@media (max-width: 550px) {
    .titulo_login {
        font-size: 20px;
    }

    section.fundo_entrar h1 {
        transform: translateY(20px);
        font-size: 1.2rem;
    }

    #borda_img_p {
        height: 150px;
    }

    section.fundo_entrar {
        height: 150px;
    }

    section.fundo_entrar::before {
        height: 150px;
    }

    .preco_do_botao {
        width: 200px;
    }
}

@media (max-width: 500px) {
    .preco_do_botao {
        width: 180px;
        transform: translate(10px, -50px);
    }
    .ver_compras_cart {
        top: 120px;
    }
}

@media (max-width: 475px) {
    .titulo_login {
        font-size: 18px;
    }

    .preco_do_botao {
        width: 180px;
    }

    .card_cart_pai_reapeat {
        width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }

    .img_cart_img {
        height: 200px;
    }
}

@media (max-width: 450px) {
    .preco_do_botao {
        width: 300px;
    }

    section.fundo_entrar h1 {
        font-size: 13px;
    }

    section.fundo_entrar p {
        font-size: 10px;
    }

    #borda_img_p {
        height: 100px;
    }

    section.fundo_entrar {
        height: 100px;
    }

    section.fundo_entrar::before {
        height: 100px;
    }
}

@media (max-width: 400px) {
    .img_cart_img {
        height: 180px;
    }


    .card_cart_pai_reapeat {
        width: 95%;
    }

    .titulo_login {
        font-size: 18px;
    }
}

@media (max-width: 350px) {
    .preco_do_botao {
        width: 250px;
    }
}

@media (max-width: 375px) {
    .preco_do_botao {
        width: 250px;
    }
}





/*novo*/


@media(max-width: 992px) {
    .pagamento_final_compra{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        width: 100%;
    }

    .paga_final_1{
        width: 675px;
        margin: 0 auto;
    }
}


@media(max-width: 694px) {
    .banco_paga{
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        width: 100%;

    }



    @media(max-width: 695px) {
    
        .paga_final_1{
            width: 500px;
            margin: 0 auto;
        }
    }

    @media(max-width: 517px) {
    
        .paga_final_1{
            width: 300px;
            margin: 0 auto;
        }
    }
        

    button.enviar_button_pagamentos{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 1rem;
    }

    button.btn_banco{
        width: 110px;
    }
}

@media(max-width: 500px) {
    /*Formatando o espaçamento entre os botoes do carrinho*/

    .baixo_cart_baixo button,
    .enviar_button_pagamentos {
        margin: 5px;
        font-size: 0.6rem;     
    }
}

@media(max-width: 310px) {
    /*Formatando o espaçamento entre os botoes do carrinho*/

    .baixo_cart_baixo {
        margin: 5px;
        font-size: 0.6rem;   
        display: grid; 
        justify-content: center;
        align-items: center;
    }
}


/*Responsividade de Finalizar compra*/
/*Solicitar Transporte*/

@media(max-width: 901px) {
     .tabela_final {
        margin: 0 auto;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .info-finalizar-compra{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
          width: 700px;
    }
    
    .localizacao_destino select {
        margin: 5px;
    }

     /*Informacoes da entrega (FIM)*/
    
}

@media(max-width: 694px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 580px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
  }
  
  .localizacao_destino select {
      padding-top: 6px;
      margin: 5px;
      width: 490px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      flex-direction: column;
  }

    .form_destino_botao{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }  

    /* button.btn_laranja{
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    } */

   /*Informacoes da entrega (FIM)*/
}



@media(max-width: 574px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
  }
  
  .localizacao_destino select {
      padding-top: 6px;
      margin: 5px;
      width: 380px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

   /*Informacoes da entrega (FIM)*/
}



@media(max-width: 505px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    #info-entrega-destino{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .localizacao_destino {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
  }
  
  .localizacao_destino select {
      padding-top: 6px;
      margin: 7px;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

   /*Informacoes da entrega (FIM)*/
}


@media(max-width: 502px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
  }
  
  .localizacao_destino select {
      margin: 2px;
  }

  /*Tipografia do Texto*/

  #txt-inicio-servico-de-entrega{
    font-size: 1.2rem;
  }

  h1#txt-info-entrega{
    font-size: 0.7rem;
  }
  

   /*Informacoes da entrega (FIM)*/
}



@media(max-width: 359px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
  }
  
  .localizacao_destino select {
      padding-top: 6px;
      margin: 7px;
      width: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

  .form_destino_botao{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100px;
}  

button.btn_laranja{
    width: 388px;
    font-size: 0.8rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

   /*Informacoes da entrega (FIM)*/
}



@media(max-width: 502px) {
    /*Informacoes da entrega (Selecionar Destino)*/

    .localizacao_destino {
        width: 580px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
  }
  
  .localizacao_destino select {
      margin: 2px;
  }
  
  

   /*Informacoes da entrega (FIM)*/
}

/*Informe o local*/

div#txt-local-entrega{
    font-size: 25px;
    color: #fff;
    text-align: center;
    margin-top: 15px;
}

@media (max-width: 500px){
    div#txt-local-entrega h1{
        font-size: 16px;
    }
} 


@media(max-width: 604px) {
    .tabela_final_t table{
    width: 50%;
    margin: 0 auto;        
   }  
}

@media(max-width: 524px) {
    .tabela_final_t {
       font-size: 0.9rem;
   }  

   .img_cart_img_final{
    width: 55px;
    height: 45px;
   }
}


@media(max-width: 445px) {
    .tabela_final_t table{
    width: 30px;
    font-size: 0.8rem;
   }  

   .img_cart_img_final{
    width: 50px;
    height: 40px;
   }
} 


@media(max-width: 428px) {
    .tabela_final_t table{
    width: 25px;
    font-size: 0.7rem;
   }  

   .img_cart_img_final{
    width: 45px;
    height: 35px;
   }
} 


@media(max-width: 409px) {
    .tabela_final_t table{
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        min-width: 90%;
        border: none;
   }  

   .g_total{
    min-width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    font: 0.7rem;
   }

   .g_total-1{
    min-width: 100%;
    /* height: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    font: 0.7rem;
   }
}
 
@media(max-width: 280px) {
    .tabela_final_t table{
        min-width: 100%;
   }  
   .img_cart_img_final{
    width: 30px;
    height: 25px;
   }
}

h1#txt-selecionar-transporte{
    font-size: 1.2rem;    
}


/*Transporte*/
/* ----------------- Input ------------------------*/

@media(max-width: 451px) {
    h1#txt-selecionar-transporte{
        font-size: 0.8rem;    
    }
    
}


@media(max-width: 451px) {
    .transporte_and_input {
        display: flex;
        flex-direction: column;
        /* display: none; */
    }
    
    .pai_pesquisa {
        width: 100%;
        width: 100%;
        border-radius: 30px;
        border: 2px solid #fff;
        padding: 5px 20px;
        background: #ffffff7a;
        color: #000;
        outline: none;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    
    .pai_pesquisa input {
    width: 100%;
    font-size: 18px;
    border: none;
    background-color: transparent;
    outline: none;
    margin-bottom: 10px;

}

.img_pesquisa {
    background: url("../../components/imgs/icons/icon_pesquisa.png");
    /* background-image: url('../imgs/icons/icon_pesquisa.png'); */
    width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}
}

@media(max-width: 443px) {
    .transporte_and_input {
        display: flex;
        flex-direction: column;
        /* display: none; */
    }

    #doisFinal-1{
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*Barra de Pesquisa de transportes disponiveis*/
    
    .pai_pesquisa {
        width: 270px;
        border-radius: 30px;
        border: 2px solid #fff;
        padding: 5px 20px;
        background: #ffffff7a;
        color: #000;
        outline: none;
        margin-bottom: 15px;
     
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    
    .pai_pesquisa input {
    width: 200px;
    font-size: 18px;
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}
}

/*formatando o botao para a escolha do transporte*/

h1#selecione-transporte{
    color: white;
    font-size: 25px;
    color: fff;
    text-align: center;
    margin-top: 15px;
}

@media(max-width: 415px) {
    .btn_escolher, .btn_laranja {
        padding: 4px;
        font-size: 12px;
    }

    /* .barra-laranja-transporte{
        background: red;
    } */

    h1#selecione-transporte{
        font-size: 17px;
        color: fff;
        text-align: center;
        margin-top: 15px;
    }
}


/*Formatando o posicionamento dos cards de finalizar compra (solicitar o transporte)*/

@media(max-width: 401px) {
    .tabela_final {
       margin: 0 auto;
       border-radius: 20px;
       display: flex;
       justify-content: center;
       flex-direction: column;
   }

   .info-finalizar-compra{
       display: flex;
       justify-content: center;
       align-items: center;
       margin: 0 auto;
       width: 100%;
   }
}


.valor-preco-inicial-leilao{
    margin-top: 40px;
    background-color: #9ba236;
    text-align: center;
    border-radius: 20px;
}

@media(max-width: 1020px) {
    .valor-preco-inicial-leilao{
        margin: 0px;
        background-color: #9ba236;
        text-align: center;
        border-radius: 20px;
    }
}   


/*Formatando a entrega final *Confirmacao do transporte*(*/

@media (max-width: 900px){
    .entrega_final{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.entrega_final>div {
background-color: #bdc466;
border-radius: 20px;
width: 100%;
padding: 0;
padding-bottom: 10px;
}

} 

@media (max-width: 530px){
    .entrega_final{
    font-size: 1rem;
}

}

/*Responvidade do servico de entregas (ao mostrar o total de transporte para o cliente)*/

@media(max-width: 780px) {
     div#mostrar-total-d-transporte{
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    } 
}  


@media(max-width: 691px) {
    div#mostrar-total-d-transporte{
    width: 100px;
   } 

   div#mostrar-total-d-transporte table{
    width: 100px;
    font-size: 0.8rem;
   } 
}

@media(max-width: 834px) {

   div#mostrar-total-d-transporte table{
    width: 100px;
    font-size: 0.8rem;
   } 
}

@media(max-width: 669px) {
    div#mostrar-total-d-transporte .verde_total_entrega table{
       width: 100%;
       display: grid;
       justify-content: center;
       align-items: center;
       margin: 0 auto;
       text-align: left;
    }
} 


@media(max-width:522px){
    div#mostrar-total-d-transporte{
        width: 95%;
        margin: 5px;
        overflow-x: scroll;
        transform: translateX(5px);
        border-radius: 10px;
        font-size: 12px;
    }

    /* table.teste-posicao{
        transform: translateX(100px);
    } */

}


 /*fim da formatacao de mostrar o total de entrega*/


 /*Novo*/

/* table#dados{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; } */

/*Fim*/ 


@media(max-width: 522px) {
    div.verde_total_entrega{
     display: block;
    }
 } 
 
 div.verde_total_entrega button{
    margin: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 @media(max-width: 669px) {
    div.verde_total_entrega button{
       width: 200px;
       display: flex;
       justify-content: center;
       align-items: center;
       margin: 0 auto;
       font-size: 0.8rem;
       margin-bottom: 7px;
    }
 }


 @media(max-width: 520px) {   

/**/
    #ScrollDiv { /* 
        width: 500px;
        overflow-x: auto;
        height: 200px;
       margin-left: auto;
        margin-right: auto;
        display: none; */
      }

      table#dados {
        width: 100%;
        position: relative !important;
      }    
    
    /* div.rolamento{
        display: grid;
        height: auto;
    }

    div#eriv{
        background: red;
        width: 150px;
    } */
}    

 /* div.rolamento{
    overflow-x: auto;
}   */

/*Toda responsividade que tem haver com o processo de compras.*/




@media(max-width:400px)
{
    /* .testando-posicao
    {
        margin-left: 17px;
        width: 100%;
    } */

    table.tabela-transporte{
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}




/* #teste-de-trans{
    background: red;
    width: 400px;
} */


@media(max-width:410px) {
    .ocultar-img{
        display: none;
    }
}
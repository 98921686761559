.principal{
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-areas: 'lado direita';
    padding: 100px;
    /* grid-gap: 20px; */
    margin: 0 auto;
 }


 .lado{
    grid-area: lado;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
 }
  

/**/

.principal .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.principal .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.principal .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.principal .entry .entry-title a {
    color: #111;
    transition: 0.3s;
}

.principal .entry .entry-title a:hover {
    color: #1bbd36;
}

.principal .entry .entry-meta {
    margin-bottom: 15px;
    color: #777777;
}

.principal .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.principal .entry .entry-meta ul li+li {
    padding-left: 20px;
}

.principal .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}


.principal .entry .entry-meta a {
    color: #847872;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.principal .entry .entry-content p {
    line-height: 24px;
}

.principal .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.principal .entry .entry-content .read-more a {
    display: inline-block;
    background: #1bbd36;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}

.principal .entry .entry-content .read-more a:hover {
    background: #1ed33c;
}

.principal .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.principal .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.principal .entry .entry-content blockquote p {
    color: #4d4643;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.principal .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #111;
    margin-top: 20px;
    margin-bottom: 20px;
}

.principal .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.principal .entry .entry-footer i {
    color: #5e5e5e;
    display: inline;
}

.principal .entry .entry-footer a {
    color: #1e1e1e;
    transition: 0.3s;
}

.principal .entry .entry-footer a:hover {
    color: #1bbd36;
}

.principal .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.principal .entry .entry-footer .cats li {
    display: inline-block;
}

.principal .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.principal .entry .entry-footer .tags li {
    display: inline-block;
}

.principal .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.principal .entry .entry-footer .share {
    font-size: 16px;
}

.principal .entry .entry-footer .share i {
    padding-left: 5px;
}

.principal .entry-single {
    margin-bottom: 30px;
}

/**/
 
 .direita{
    background: #fff; 
    grid-area: direita;
    display: grid;
    /* justify-content: center;
    align-items: center;
    margin: 0 auto; */
    border-radius: 10px;
    max-height: 600px;
 }
 


 /**/

 .principal .direita {
    padding: 10px;
    margin: 0 0 0px 20px;
    /* box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); */
}

.principal .direita .direita-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #111;
    position: relative;
}

.principal .direita .direita-item {
    margin-bottom: 30px;
}

.principal .direita .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}

.principal .direita .search-form form input[type="text"] {
    border: 0;
    padding: 4px;
    border-radius: 10px;
    width: calc(100% - 40px);
}

.principal .direita .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #1bbd36;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}

.principal .direita .search-form form button i {
    line-height: 0;
}

.principal .direita .search-form form button:hover {
    background: #1ecf3b;
}

.principal .direita .categories ul {
    list-style: none;
    padding: 0;
}

.principal .direita .categories ul li+li {
    padding-top: 10px;
}

.principal .direita .categories ul a {
    color: #111;
    transition: 0.3s;
}

.principal .direita .categories ul a:hover {
    color: #1bbd36;
}

.principal .direita .categories ul a span {
    padding-left: 5px;
    color: #b4aca8;
    font-size: 14px;
}

.principal .direita .recent-posts h4 {
    font-size: 15px; 
    margin-left: 95px;
    font-weight: bold;
}

.principal .direita .recent-posts h4 a {
    color: #111;
    transition: 0.3s;
}

.principal .direita .recent-posts h4 a:hover {
    color: #1bbd36;
}

.principal .direita .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #b4aca8;
}

.principal .direita .tags {
    margin-bottom: -10px;
}

.principal .direita .tags ul {
    list-style: none;
    padding: 0;
}

.principal .direita .tags ul li {
    display: inline-block;
}

.principal .direita .tags ul a {
    color: #515151;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #c4c4c4;
    display: inline-block;
    transition: 0.3s;
}

.principal .direita .tags ul a:hover {
    color: #fff;
    border: 1px solid #1bbd36;
    background: #1bbd36;
}

.principal .direita .tags ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
}


/*Rcent Post*/

/* .principal .direita .recent-posts .post-item+.post-item {
    margin-top: 15px;
} */

.principal .direita .recent-posts img {
    width: 80px;
    float: left;
}
.entry-img img{
    width: 100%;
}

.principal .direita .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}

.principal .direita .recent-posts h4 a {
    color: #111;
    transition: 0.3s;
}

.principal .direita .recent-posts h4 a:hover {
    color: #1bbd36;
}

.principal .direita .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #b4aca8;
}

 /**/
 

 
 @media (max-width:999px) {

    .principal{
        display: grid;
        grid-template-columns:13fr;
        grid-template-areas: 'lado'
                             'direita';
        padding: 50px;
        margin: 20px;
     }

    .principal .lado{
        margin-bottom: 70px;
    }
    
 }

 @media (max-width:580px) {

    .principal{
        max-width: 600px;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .principal .lado{
        max-width: 500px;
    }

    .principal .direita{
        max-width: 500px;
    }

    .principal .lado{
        margin-bottom: -20px;
    }
    
 }


 @media (max-width:509px) {

    .principal{
        max-width: 533px;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .principal .lado{
        max-width: 400px;
    }

    .principal .direita{
        max-width: 400px;
    }
    
 }

 @media (max-width:406px) {

    .principal{
        max-width: 500px;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .principal .lado{
        max-width: 350px;
    }

    .principal .direita{
        max-width: 350px;
    }
    
 }

 @media (max-width:367px) {

    .principal{
        max-width: 400px;
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .principal .lado{
        max-width: 310px;
    }

    .principal .direita{
        max-width: 310px;
    }
    
 }


 @media (max-width:317px) {
     .principal .entry .entry-img {
    max-height: 400px;
    margin: -27px -27px 10px -27px;
    overflow: hidden;
}

.principal .entry .entry-title {
    font-size: 20px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 14px 0;
}

.principal .direita .recent-posts img {
    width: 60px;
    float: left;
}
 }



 /*Botão Ler mais*/

 .principal .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.principal .entry .entry-content .read-more a {
    display: inline-block;
    background: #424616;
    color: #fff;
    padding: 7px 40px;
    transition: 0.3s;
    font-size: 15px;
    border-radius: 4px;
}

.principal .entry .entry-content .read-more a:hover {
    background: #2e300f;
}
section #inicio-animais {
    /* background: url("../imgs/Cultura Coop_Cuando Cubango II.png"); */
    background: url("../../components/imgs/Cultura Coop_Cuando Cubango II.png");
    background-position: center;
    background-size: cover;
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
}

section #inicio-animais::before {
    content: '';
    position: absolute;
    top: 4;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #00000094;
}

section #inicio-animais {
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    z-index: 10;
}

section #inicio-animais h1 {
    font-size: 40px;
    margin: 30px 20px;
}

section.animais {
    width: 100%; 
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    /* background: red; */
}

 

section.animais img {
    border-radius: 10px;
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 270px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

.img_do_cooperador {
    border-radius: 10px;
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 230px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

.img_do_animal {
    border-radius: 10px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 190px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

 section.animais .mi {
    background: #424616;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}
section.animais .mi table {
    color: #fff;
    text-align: left;
    margin: auto;
}
section.animais .mi table td{
    padding: 5px;
}

section.animais .mi table tr td:nth-child(1){
    font-weight: bold;
}

.am_p {
    max-width: 300px;
    margin: auto;
}

section.animais .mi h2 {
    color: #fff;
}

.ani_p {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    padding-bottom: 100px;
}

.button {
    margin-top: 20px;
}
.botao_para_saber_mais{
    width: 240px;
    margin-left: 20px;
    margin-right: 20px;
}

@media(max-width: 1600px) {
    .ani_p {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 95%;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}

@media(max-width: 1270px) {
    .ani_p {
        grid-gap: 40px;
        grid-template-columns: 1fr 1fr 1fr;
        width: 95%;
    }
    section.animais img {
        height: 230px;
    }
}

@media (max-width:950px) {
    section.animais img {
        height: 200px;
    }

    section.animais {
        width: 95%;
    }

    section.animais .mi h2 {
        font-size: 20px;
    }

    section.animais .mi .info_u {
        font-size: 16px;
    }

    .ani_p {
        grid-gap: 30px;
    }
}

@media (max-width: 800px) {
    section.animais img {
        height: 200px;
    }

    section.animais {
        width: 95%;
    }

    section.animais .mi h2 {
        font-size: 17px;
    }

    section.animais .mi .info_u {
        font-size: 15px;
    }
}

@media (max-width: 750px) {
    .ani_p {
        grid-template-columns: 1fr 1fr;
    }
    section.animais img {
        height: 250px;
    }
}

@media (max-width: 650px) {
    .ani_p {
        grid-gap: 15px;
    }

    section.animais img {
        height: 200px;
    }
}

@media (max-width: 500px) {
    .ani_p {
        grid-gap: 10px;
    }

    section.animais img {
        height: 180px;
    }

    section #inicio-animais h1 {
        font-size: 30px;
    }

    section #inicio-animais p {
        font-size: 19px;
    }
}

@media (max-width: 450px) {
    .button {
        margin-top: 30px;
    }

    .ani_p {
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }

    .vai_subir:nth-child(even) {
        margin-top: -150px;
    }

    section.animais img {
        height: 250px;
        /* display: grid;
        justify-content: center;
        align-items: center;
        margin: 0 auto; */
    }
}

@media (max-width: 360px) {
    section #inicio-animais h1 {
        font-size: 25px;
    }

    section #inicio-animais p {
        font-size: 16px;
    }
}
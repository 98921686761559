.video {
    margin: 15 0 15 0;
}

section #inicio-leilao {
    background: url("../../components/imgs/Cultura Coop_Cuando Cubango II.png");
    /* background: url("../imgs/Cultura Coop_Cuando Cubango II.png"); */
    background-position: center;
    background-size: cover; 
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
}

.is-link:hover {
    background-color: #b46739;
}
  
section #inicio-leilao {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section.leilao_online {
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.fundo_leilao_online {
    background: url("../../components/imgs/Cultura Coop_Cuando Cubango II.png");
    /* background: url("../imgs/Cultura Coop_Cuando Cubango II.png"); */
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: 600px;
    max-width: 100%;
    margin-top: 5rem;
}

section.fundo_leilao_online::before {
    content: '';
    position: absolute;
    top: 1;
    left: 0;
    height: 600px;
    width: 100%;
    background: #00000094;
}

section.fundo_leilao_online {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section .welcome-leilao {
    position: absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

section .welcome-leilao h1 {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    text-align: center;
    font-size: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

.l_divider {
    height: 100px;
}

section .welcome-leilao p {
    text-align: justify;
    color: #fff;
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 700px;
    z-index: 10;
}

section.leilao {
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.leilao .columns img {
    border-radius: 10px;
    max-width: 285px;
    background-size: cover;
    background-position: center;
    height: 270px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

section.leilao .mi {
    background: #424616;
    padding: 10px;
    margin: 40px;
    border-radius: 15px;
    height: 600px;
}

section.leilao p {
    font-weight: 600;
    text-align: center;
}

.welcome-leilao a.button {
    box-shadow: inset 250px 0 0 #9ba236;
    color: white;
    border: none;
    border-radius: 24px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.welcome-leilao a.button:hover {
    box-shadow: inset 250px 0 0 #b46739;
    color: white;
    transition: 0.5s ease-in;
}

a.button {
    border: none;
    border-radius: 24px;
    transition: .3s;
}

a.button:hover {
    background-color: #b46739;
}

video {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lance_p {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 5px;
    border-bottom: 1px solid #b46739;
    text-align: center;
}

.lance_h2 {
    border-bottom: 1px solid #b46739;
} 
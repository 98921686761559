::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #9ba236);
    border-radius: 12px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#9ba236, #9ba236);
  }
  
  @media (max-width: 576px) {
    ::-webkit-scrollbar {
      width: 4px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: linear-gradient(transparent, #9ba236);
      border-radius: 6px;
    }
  a
    ::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(#9ba236, #9ba236);
    }
  }
  